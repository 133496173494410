import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import css from './NotAvailableContent.module.css';


const NotAvailableContent = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="NotAvailableContent.title" />
      </h2>
    </div>
  )
};

NotAvailableContent.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

NotAvailableContent.propTypes = {
  rootClassName: string,
  className: string,
};

export default NotAvailableContent;
