import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import { compose } from 'redux';
import arrayMutators from 'final-form-arrays';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { array, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { languagesHelper as language } from '../../../helpers/languages';
import {
  timeOfDayFromLocalToTimeZone,
  getStartOf,
  makeBookingRangesFromTimeSlotsHourly,
  getMonthlyTimeSlots,
  monthIdString,
  getTimeSlots,
  getAllTimeValues,
} from '../../../util/dates';

import { propTypes, isPatientRole, isDentistRole } from '../../../util/types';
import { BOOKING_PROCESS_NAME } from '../../../transactions/transaction';
import {
  Form,
  H6,
  IconSpinner,
  Modal,
  BookingEmailsForm,
  Button,
  FieldCheckbox,
  FieldRadioButton,
} from '../../../components';
import EstimatedCustomerForCardBreakdownMaybe from './EstimatedCustomerForCardBreakdownMaybe';
import BookingItemForCard from './BookingItemForCard';

import { handleSubmit } from '../../../containers/ListingPage/ListingPage.shared';

import defaultConfig from '../../../config/configDefault';
import css from './BookingTimeFormForCard.module.css';

const TODAY = new Date();
const QUARTER_HOUR = 0.25;

const scanData =  defaultConfig.typeOfScanerOptions.reduce((acc, item) => {
  acc[item.key] = item.label;
  return acc;
}, {});

export class BookingTimeFormForCardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTimeSlot: null,
      currentMonth: getStartOf(TODAY, 'month', props.timeZone),
      showAllTimeSlots: false,
      showBreakdown: false,
      bookingData: {},
      formValues: {},
    };

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.onChangeCurrentMonth = this.onChangeCurrentMonth.bind(this);
    this.setFormValues = this.setFormValues.bind(this);
    this.onCloseBreakdown = this.onCloseBreakdown.bind(this);
  }

  handleFormSubmit() {

    const {
      listingId,
      listingSlug,
      history,
      routeConfiguration,
      currentUser,
      callSetInitialValues,
      getListing,
      onInitializeCardPaymentData,
      conversionRates, 
      currentCurrency
    } = this.props;

    const params = {
      id: listingId?.uuid,
      slug: listingSlug
    }

    const commonParams = { params, history, routes: routeConfiguration };

    const onSubmit = handleSubmit({
      ...commonParams,
      currentUser,
      callSetInitialValues,
      getListing,
      onInitializeCardPaymentData,
      useCustomCalendarState: true,
      conversionRates, 
      currentCurrency
    });

    const baseEmail = this.state.formValues?.values?.email;
    const additionalEmails = this.state.formValues?.values?.additionalEmails;
    const emails = !!additionalEmails?.length ? [baseEmail].concat(additionalEmails) : [baseEmail];

    let data = this.state.bookingData;
    data.emails = emails

    onSubmit(data);
  }

  setFormValues(values) {
    this.setState({ formValues: values })
  }

  onChangeCurrentMonth(month) {
    this.setState({ currentMonth: month })
  }


  // When the values of the form are updated we need to fetch
  // lineItems from this template's backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the orderData object.
  async handleOnChange(timeSlot, form, selectedPriceType) {
    // const { bookingStartTime, bookingEndTime } = formValues.values;


    this.setState({
      selectedTimeSlot: timeSlot
    }, () => {
      const { selectedTimeSlot } = this.state;

      const { conversionRates, currentCurrency, listingCurrency } = this.props;

      const bookingStartDate = selectedTimeSlot?.attributes?.start || null;
      const bookingEndDate = selectedTimeSlot?.attributes?.end || null;

      // const startDate = bookingStartTime ? timestampToDate(bookingStartTime) : null;
      // const endDate = bookingEndTime ? timestampToDate(bookingEndTime) : null;

      const listingId = this.props.listingId;
      const isOwnListing = this.props.isOwnListing;

      // Note: we expect values bookingStartTime and bookingEndTime to be strings
      // which is the default case when the value has been selected through the form
      const isStartBeforeEnd = bookingStartDate < bookingEndDate;

      if (
        bookingStartDate &&
        bookingEndDate &&
        isStartBeforeEnd &&
        !this.props.fetchLineItemsInProgress &&
        selectedPriceType
      ) {
        this.props.onFetchTransactionLineItems({
          orderData: { bookingStart: bookingStartDate, bookingEnd: bookingEndDate },
          listingId,
          isOwnListing,
          selectedPriceType,
          currentCurrency,
          listingCurrency
        });
      }

      const startDateFromSlot = selectedTimeSlot?.attributes?.start;
      const endDateFromSlot = selectedTimeSlot?.attributes?.end;
      // const { monthlyTimeSlots, timeZone, intl, form, startDateFromSlot } = this.props;
      const { monthlyTimeSlots, timeZone = "CET", intl } = this.props;
      if (!startDateFromSlot) {
        !!form && form.batch(() => {
          form.change('bookingStartTime', null);
          form.change('bookingEndDate', { date: null });
          form.change('bookingEndTime', null);
        });
        // Reset the currentMonth too if bookingStartDate is cleared
        // this.setState({ currentMonth: getStartOf(TODAY, 'month', timeZone) });
        return;
      }

      // This callback function (onBookingStartDateChange) is called from react-dates component.
      // It gets raw value as a param - browser's local time instead of time in listing's timezone.
      const startDate = timeOfDayFromLocalToTimeZone(startDateFromSlot, "CET");
      const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, "CET");
      const timeSlotsOnSelectedDate = getTimeSlots(timeSlots, startDate, "CET");

      const { startTime, endDate, endTime } = getAllTimeValues(
        intl,
        "CET",
        timeSlotsOnSelectedDate,
        startDate
      );

      // !!form && form.batch(() => {
      //   form.change('bookingStartDate', { date: startDateFromSlot });
      //   form.change('bookingEndDate', { date: endDateFromSlot });
      //   form.change('bookingStartTime', startTime);
      //   form.change('bookingEndTime', endTime);
      // });


      this.setState({
        bookingData: {
          bookingStartTime: startTime,
          bookingEndTime: endTime,
          bookingStartDate: { date: startDateFromSlot },
          bookingEndDate: { date: endDateFromSlot },
          ...selectedPriceType,
        }
      })
      this.setState({ showAllTimeSlots: false })

      if (bookingStartDate && bookingEndDate) {
        this.setState({ showBreakdown: true })
      }
    });
  }

  onCloseBreakdown() {
    this.setState({ showBreakdown: false });
    this.setState({ selectedTimeSlot: null });
  }

  render() {
    const {
      rootClassName,
      className,
      price: unitPrice,
      dayCountAvailableForBooking,
      marketplaceName,
      ...rest
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        mutators={{ ...arrayMutators }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            values,
            monthlyTimeSlots,
            timeZone,
            lineItems,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            onManageDisableScrolling,
            listingMainInfo,
            currentUser,
            scanTypes,
            typesOfScans,
            getAdminProfileInProgress,
            getAdminProfileError,
            conversionRates,
            currentCurrency,
            listingCurrency
          } = fieldRenderProps;

          const isPatient = isPatientRole(currentUser);
          const isDentist = isDentistRole(currentUser);

          const timeSlots = getMonthlyTimeSlots(monthlyTimeSlots, this.state.currentMonth, "CET");
          const quarterHourRanges = timeSlots && makeBookingRangesFromTimeSlotsHourly(timeSlots, QUARTER_HOUR);

          // collect the data in object with keys 'ddd D MMM'
          const filtered = {};
          quarterHourRanges.map((item) => {
            const hasData = item && item.length;
            const labelDate = hasData && moment(item[0].attributes.start).clone().format('ddd D MMM');
            if (labelDate) {
              if (!filtered[labelDate]) {
                filtered[labelDate] = [item[0]]
              } else {
                filtered[labelDate] = [...filtered[labelDate], item[0]]
              }
            }
          })

          // sort collected data in a chronotimeSlotsical way
          const sortedArray = _.orderBy(
            Object.entries(filtered),
            o => +(moment(o[1][0].attributes.start).format('YYYYDDDD')),
            ['asc']);

          const selectedStartDate =
            (values?.bookingStartDate?.date &&
              moment(values.bookingStartDate.date).format('ddd D MMM')) ||
            null;
          const selectedStartDateIndex = sortedArray.findIndex(
            item => item?.[0] === selectedStartDate
          );
          const startIndex = selectedStartDateIndex > -1 ? selectedStartDateIndex : 0;


          // get four first and the closest days from an array of sorted items
          let fourDaysItems = sortedArray.slice(startIndex, startIndex + 4);
          const { selectedTimeSlot } = this.state;

          const startDate = selectedTimeSlot?.attributes?.start || null;
          const endDate = selectedTimeSlot?.attributes?.end || null;

          // This is the place to collect breakdown estimation data. See the
          // EstimatedCustomerBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const breakdownData =
            startDate && endDate
              ? {
                startDate,
                endDate,
              }
              : null;

          const showEstimatedBreakdown =
            breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;

          const currency = defaultConfig.currency;

          const lastAvailabelDay = fourDaysItems?.[fourDaysItems?.length - 1]?.[0];
          const lastAvailabelSlots = fourDaysItems?.[fourDaysItems?.length - 1]?.[1];

          let blockedTimeSlots = [
            [
              moment(lastAvailabelDay).add(1, 'd').format('ddd D MMM'),
              lastAvailabelSlots,
              { isBlocked: true },
            ],
            [
              moment(lastAvailabelDay).add(2, 'd').format('ddd D MMM'),
              lastAvailabelSlots,
              { isBlocked: true },
            ],
            [
              moment(lastAvailabelDay).add(3, 'd').format('ddd D MMM'),
              lastAvailabelSlots,
              { isBlocked: true },
            ],
          ]

          if (!fourDaysItems?.length) return (
            <p className={css.rootSpaceHolder} >
              <FormattedMessage id="ListingCard.noTimeSlots" />
            </p>
          )

          if (fourDaysItems?.length < 4) {
            fourDaysItems = [...fourDaysItems, ...blockedTimeSlots.slice(0, 4 - fourDaysItems?.length)]
          }

          const sendEmailsEnabled = this.state.formValues?.values?.sendEmails === "sendEmailsYes";
          const emailsPresent = this.state.formValues?.values?.email;


          return (
            <Form onSubmit={this.handleFormSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={val => {
                  if (val.values.scanType !== values.scanType && this.state.selectedTimeSlot) {
                    this.handleOnChange(this.state.selectedTimeSlot, form, {
                      scanType: val?.values?.scanType,
                    });
                  }
                }}
              />

              <div className={css.bookingTabsContentHolder}>
                {fourDaysItems && fourDaysItems.map((dayItem, idx) => {
                  return <BookingItemForCard
                    // handleSubmit={handleSubmit}
                    dayItem={dayItem}
                    key={`dayItem${idx}`}
                    selectedDuration={QUARTER_HOUR}
                    handlePopup={(item) => this.handleOnChange(item, form, { scanType: typesOfScans?.[0] })}
                    selectedTimeSlot={this.state.selectedTimeSlot}
                  />
                })}
              </div>
              <div className={css.bookingTabsContentBottom}>
                <div className={css.bookingTabsContentBottomLine} />
                <div className={css.bookingTabsContentBottomLink} onClick={() => this.setState({ showAllTimeSlots: true })}>
                  <FormattedMessage id="ListingCard.viewAllAvailableTime" />
                </div>
              </div>
              <Modal
                id="EstimatedCustomerForCardBreakdown.info"
                contentClassName={css.inquiryModalContent}
                isOpen={this.state.showBreakdown}
                // onClose={() => this.setState({ showBreakdown: false})}
                onClose={() => this.onCloseBreakdown()}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.breakdownWrapper}>
                  {showEstimatedBreakdown ? (
                    <div className={css.breakdown}>
                      {listingMainInfo}

                      <div className={css.pricesContainer}>
                        <label htmlFor="#" className={css.prices}>
                          <FormattedMessage id="BookingTimeForm.scanTypeLabel" />
                        </label>
                        {typesOfScans
                          ? typesOfScans.map((scan, i) => {

                            return (
                              <div className={css.priceRow}>
                                <FieldRadioButton
                                  label={language.labelsTranslator(scan, intl)}
                                  id={scan}
                                  name="scanType"
                                  value={scan}
                                  defaultValue={i === 0 ? scan : undefined}
                                />
                                {/* <div>
                                  {currency} {scanTypes[scan]}
                                </div> */}
                              </div>
                            );
                          })
                          : ''}
                      </div>


                      <div className={css.priceBreakdownContainer}>
                        <H6 as="h3" className={css.bookingBreakdownTitle}>
                          <FormattedMessage id="BookingTimeForm.priceBreakdownTitle" />
                        </H6>
                        <hr className={css.totalDivider} />
                        <EstimatedCustomerForCardBreakdownMaybe
                          breakdownData={breakdownData}
                          lineItems={lineItems}
                          timeZone="CET"
                          currency={unitPrice.currency}
                          marketplaceName={marketplaceName}
                          processName={BOOKING_PROCESS_NAME}
                          scanType={this.state?.bookingData?.scanType}
                        />
                      </div>


                      <BookingEmailsForm
                        setFormValues={this.setFormValues}
                        isCardView
                        initialValues={{ sendEmails: "sendEmailsYes" }}
                      />

                      {sendEmailsEnabled && emailsPresent && (
                        <FieldCheckbox
                          className={css.emailConfirm}
                          id="emailConfirm"
                          name="emailConfirm"
                          label={intl.formatMessage({ id: 'BookingTimeForm.emailConfirmText' })}
                          value="emailConfirm"
                        />
                      )}


                      {isPatient && (
                        <div className={css.submitButton}>
                          <Button
                            onClick={() => this.handleFormSubmit()}
                            inProgress={fetchLineItemsInProgress}
                            disabled={isOwnListing || (sendEmailsEnabled && !values?.emailConfirm?.length)}
                          >
                            <FormattedMessage id="BookingTimeForm.requestToBook" />
                          </Button>
                        </div>
                      )}

                      <p className={css.finePrint}>
                        <FormattedMessage
                          id={
                            isOwnListing
                              ? 'BookingTimeForm.ownListing'
                              : 'BookingTimeForm.youWontBeChargedInfo'
                          }
                        />
                      </p>

                      <div className={css.cancelButton}>
                        <Button onClick={() => this.setState({ showBreakdown: false })}>
                          <FormattedMessage id="BookingTimeForm.cancel" />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    <div className={css.breakdown}>
                      {listingMainInfo}
                      <IconSpinner className={css.breakdownSpinner} />
                    </div>
                  )}
                </div>
              </Modal>
              <Modal
                id="BookingTimeFormForCard.timeSlots"
                contentClassName={css.inquiryModalContent}
                isOpen={this.state.showAllTimeSlots}
                onClose={() => this.setState({ showAllTimeSlots: false })}
                usePortal
                onManageDisableScrolling={onManageDisableScrolling}
              >
                <div className={css.timeSlotsWrapper}>
                  <h2>
                    <FormattedMessage id="BookingTimeForm.selectTime" />
                  </h2>
                  <div className={classNames(css.bookingTabsContentHolder, css.bookingTabsContentHolderNoScroll)}>
                    {fourDaysItems && fourDaysItems.map((dayItem, idx) => {
                      return <BookingItemForCard
                        // handleSubmit={handleSubmit}
                        dayItem={dayItem}
                        key={`dayItem${idx}`}
                        selectedDuration={QUARTER_HOUR}
                        handlePopup={(item) => this.handleOnChange(item, form, { scanType: typesOfScans?.[0] })}
                      />
                    })}
                  </div>
                </div>
              </Modal>
            </Form>
          );
        }}
      />
    );
  }
}

BookingTimeFormForCardComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormForCardComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string.isRequired,

  onFetchTransactionLineItems: func,
  lineItems: array,
  fetchLineItemsInProgress: bool,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

  dayCountAvailableForBooking: number.isRequired,
};

const BookingTimeFormForCard = compose(injectIntl)(BookingTimeFormForCardComponent);
BookingTimeFormForCard.displayName = 'BookingTimeFormForCard';

export default BookingTimeFormForCard;
