import React, { useEffect, useState } from 'react';
import enFlag from './icons/enFlagIcon.png';
import frFlag from './icons/frFlagIcon.png';

import { Menu, MenuLabel, MenuContent, MenuItem, IconGlobe } from '../../components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { handleSwitchLanguage } from '../../ducks/ui.duck';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';

import css from './MultiLanguageSelect.module.css';
import classNames from 'classnames';

const MultiLanguageSelectComponent = props => {
  const { onHandleSwitchNewLanguage, language, onUpdateProfile, currentUser } = props;

  const [isOpen, setIsOpen] = useState(false)

  const onToggleActive = (isOpen) => {
    setIsOpen(isOpen)
  }

  //set language to saved value if any
  useEffect(() => {
    const savedLanguageMaybe =
      currentUser?.attributes?.profile?.protectedData?.language ||
      window.localStorage.getItem('assenzDentalLanguage') || language;

    if (language !== savedLanguageMaybe) {
      onHandleSwitchNewLanguage(savedLanguageMaybe);
    }
  }, []);

  const handleLanguageSwitch = async lang => {
    //change language state
    await onHandleSwitchNewLanguage(lang);
    //update currentUser
    if (currentUser?.id?.uuid) {
      await onUpdateProfile({
        protectedData: {
          language: lang,
        },
      });
    }

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('assenzDentalLanguage', lang);
    }
    if (typeof document === undefined) {
      return;
    }
    document.location.reload(true);

    setIsOpen(false)
  };


  let flagLabel = frFlag;
  switch (language) {
    case 'en':
      flagLabel = enFlag;
      break;
    case 'fr':
      flagLabel = frFlag;
      break;
  }

  return (
    <div className={css.langSwitchWrapper}>
      <Menu isOpen={isOpen} onToggleActive={onToggleActive}>
        <MenuLabel className={css.langSwitchLabel} isOpenClassName={css.langSwitchIsOpen}>
          <div className={css.langSwitchIcon} onClick={() => setIsOpen(true)}>
            {/* <IconGlobe /> */}
          <img src={flagLabel} className={css.icon} />
          </div>
        </MenuLabel>
        <MenuContent className={css.langSwitchContent} useDefaultStyle={false}>
          <MenuItem key="en">
            <div
              className={classNames(css.labelWrapper, { [css.labelWrapperActive]: language === 'en' })}
              onClick={() => handleLanguageSwitch('en')}
            >
              <img src={enFlag} className={css.icon} />
              <span className={css.label}>
                {/* EN */}
              </span>
            </div>
          </MenuItem>
          <MenuItem key="fr">
            <div
              className={classNames(css.labelWrapper, { [css.labelWrapperActive]: language === 'fr' })}
              onClick={() => handleLanguageSwitch('fr')}
            >
              <img src={frFlag} className={css.icon} />
              <span className={css.label}>
                {/* FR */}
              </span>
            </div>
          </MenuItem>
        </MenuContent>
      </Menu>
    </div>
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { language } = state.ui;

  return {
    currentUser,
    language,
  };
};

const mapDispatchToProps = dispatch => ({
  onHandleSwitchNewLanguage: newLanguage => dispatch(handleSwitchLanguage(newLanguage)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
});

const MultiLanguageSelect = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(MultiLanguageSelectComponent);

export default MultiLanguageSelect;
