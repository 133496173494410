import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { Menu, MenuLabel, MenuContent, MenuItem, IconExchange } from '../../components';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { getCurrentCurrency, setCurrentCurrency } from '../../util/currency';
import defaultConfig from '../../config/configDefault';

import css from './MultiCurrencySelect.module.css';


const MultiCurrencySelectComponent = props => {

  const currentCurrency = getCurrentCurrency()

  const [isOpen, setIsOpen] = useState(false)
  const onToggleActive = (isOpen) => {
    setIsOpen(isOpen)
  }

  useEffect(() => {
    const savedCurrencyMaybe = getCurrentCurrency();
    if (currentCurrency !== savedCurrencyMaybe) {
      setCurrentCurrency(savedCurrencyMaybe);
    }
  }, []);

  const handleCurrencySwitch = async currency => {
    await setCurrentCurrency(currency);

    if (typeof window !== 'undefined') {
      window.localStorage.setItem('assenzDentalCurrency', currency);
    }

    if (typeof document === undefined) {
      return;
    }
    document.location.reload(true);

    setIsOpen(false)
  };

  return (
    <div className={css.langSwitchWrapper}>
      <Menu isOpen={isOpen} onToggleActive={onToggleActive}>
        <MenuLabel className={css.langSwitchLabel} isOpenClassName={css.langSwitchIsOpen}>
          <div className={css.langSwitchIcon} onClick={() => setIsOpen(true)}>
            <IconExchange />
          </div>
        </MenuLabel>
        <MenuContent className={css.langSwitchContent} useDefaultStyle={false} arrowPosition={10}>
          {defaultConfig?.currencyOptions.map(c => {
            return (
              <MenuItem key={c.key}>
                <div 
                className={classNames(css.labelWrapper, {[css.labelWrapperActive]: c.key === currentCurrency})} 
                onClick={() => handleCurrencySwitch(c.key)}
                >
                  <span className={css.label}>
                    {c.label}
                  </span>
                </div>
              </MenuItem>
            )
          })}
        </MenuContent>
      </Menu>
    </div>
  );
};

const MultiCurrencySelect = compose(
  injectIntl
)(MultiCurrencySelectComponent);

export default MultiCurrencySelect;
