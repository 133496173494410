import React from 'react';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

import css from './BookingTimeFormForCard.module.css';
import classNames from 'classnames';

const BookingItemForCard = (props) => {
  const {
    dayItem,
    handlePopup,
    selectedTimeSlot
  } = props;

  const dayLabel = dayItem?.[0];
  const timeSlotsForDay = dayItem?.[1];
  const isBlocked = dayItem?.[2];

  return (
    <div className={classNames(css.bookingTabsListHolder, {[css.bookingTabsListHolderBlocked]: isBlocked})}>
      <h5 className={css.bookingTabsListTitle}>{dayLabel}</h5>
      <ul className={css.bookingTabsList}>
        {timeSlotsForDay && timeSlotsForDay.map((item, idx) => {
          const startTime = item?.attributes?.start;
          const label = moment(startTime).clone().format('h:mmA');

          const isSelected = !!selectedTimeSlot &&  moment(item?.attributes?.start).isSame(selectedTimeSlot?.attributes?.start);

          return (
            <li
              className={classNames(css.bookingTabsListItem, {[css.bookingTabsListItemSelected]: isSelected})}
              onClick={() => { handlePopup(item) }}
              key={`BookingItemForCard${idx}`}
            >
              {`${label}`}
            </li>)
        })}
      </ul>
    </div>
  )
}

export default BookingItemForCard;
