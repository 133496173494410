import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './IconDelete.module.css';

const IconDelete = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === "big") {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        fill="none"
        viewBox="0 0 28 28"
      >
        <path
          fill="#CC5858"
          d="M11.667 5.833h4.666a2.334 2.334 0 00-4.666 0zm-1.75 0a4.083 4.083 0 118.166 0h6.709a.875.875 0 110 1.75h-1.54l-1.365 14.13a4.375 4.375 0 01-4.357 3.954h-7.06a4.375 4.375 0 01-4.355-3.954L4.748 7.583h-1.54a.875.875 0 110-1.75h6.709zm2.333 5.542a.875.875 0 10-1.75 0v8.75a.875.875 0 101.75 0v-8.75zm4.375-.875a.875.875 0 01.875.875v8.75a.875.875 0 11-1.75 0v-8.75a.875.875 0 01.875-.875zM7.856 21.545a2.625 2.625 0 002.614 2.372h7.06a2.625 2.625 0 002.614-2.372l1.35-13.962H6.506l1.351 13.962z"
        ></path>
      </svg>
    )
  } else {
    return (
      <svg className={classes} width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.5 14h-7a1 1 0 0 1-1-1V4h9v9a1 1 0 0 1-1 1Zm-5-3V7m3 4V7m-8-3h13m-5-2h-3a1 1 0 0 0-1 1v1h5V3a1 1 0 0 0-1-1Z"
          strokeWidth="1.5"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

};

IconDelete.defaultProps = { className: null };

IconDelete.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconDelete;
