import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
  monthIdString,
  nextMonthFn,
  findNextQuarterBoundary
} from '../../util/dates';
import { denormalisedResponseEntities } from '../../util/data';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { transactionLineItems } from '../../util/api';
import pick from 'lodash/pick';
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range.min';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 4;

const ADMIN_ID = process.env.REACT_APP_ADMIN_ID;


// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_TIME_SLOTS_REQUEST = 'app/SearchPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/SearchPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/SearchPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/SearchPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/SearchPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/SearchPage/FETCH_LINE_ITEMS_ERROR';

export const SET_INITIAL_VALUES = 'app/SearchPage/SET_INITIAL_VALUES';

export const GET_ADMIN_PROFILE_REQUEST = 'app/SearchPage/GET_ADMIN_PROFILE_REQUEST';
export const GET_ADMIN_PROFILE_SUCCESS = 'app/SearchPage/GET_ADMIN_PROFILE_SUCCESS';
export const GET_ADMIN_PROFILE_ERROR = 'app/SearchPage/GET_ADMIN_PROFILE_ERROR';


// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],

  monthlyTimeSlots: [],
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,

  adminProfile: null,
  getAdminProfileInProgress: false,
  getAdminProfileError: null
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case FETCH_TIME_SLOTS_REQUEST: {
      // const monthlyTimeSlots = {
      //   ...state.monthlyTimeSlots,
      //   [payload]: {
      //     ...state.monthlyTimeSlots[payload],
      //     fetchTimeSlotsError: null,
      //     fetchTimeSlotsInProgress: true,
      //   },
      // };
      return { ...state };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const listingId = payload.listingId;
      const monthlyTimeSlots = state.monthlyTimeSlots.concat(
        {
          listingId,
          monthSlot: {
            [monthId]: {
              ...state.monthlyTimeSlots[monthId],
              fetchTimeSlotsInProgress: false,
              fetchTimeSlotsError: payload.error,
              timeSlots: payload.timeSlots,
            }
          }
        }
      )
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      // const monthId = payload.monthId;
      // const monthlyTimeSlots = {
      //   ...state.monthlyTimeSlots,
      //   [monthId]: {
      //     ...state.monthlyTimeSlots[monthId],
      //     fetchTimeSlotsInProgress: false,
      //     fetchTimeSlotsError: payload.error,
      //   },
      // };
      return { ...state };
    }
    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case GET_ADMIN_PROFILE_REQUEST:
      return { ...state, getAdminProfileInProgress: true, getAdminProfileError: null };
    case GET_ADMIN_PROFILE_SUCCESS:
      return { ...state, getAdminProfileInProgress: false, getAdminProfileError: null, adminProfile: payload };
    case GET_ADMIN_PROFILE_ERROR:
      return { ...state, getAdminProfileInProgress: false, getAdminProfileError: payload };


    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots, listingId) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId, listingId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const getAdminProfileRequest = () => ({ type: GET_ADMIN_PROFILE_REQUEST });
export const getAdminProfileSuccess = data => ({ type: GET_ADMIN_PROFILE_SUCCESS, payload: data });
export const getAdminProfileError = error => ({ type: GET_ADMIN_PROFILE_ERROR, payload: error });


export const searchListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  // SearchPage can enforce listing query to only those listings with valid listingType
  // NOTE: this only works if you have set 'enum' type search schema to listing's public data fields
  //       - listingType
  //       Same setup could be expanded to 2 other extended data fields:
  //       - transactionProcessAlias
  //       - unitType
  //       ...and then turned enforceValidListingType config to true in configListing.js
  // Read More:
  // https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
  const searchValidListingTypes = listingTypes => {
    return config.listing.enforceValidListingType
      ? {
        pub_listingType: listingTypes.map(l => l.listingType),
        // pub_transactionProcessAlias: listingTypes.map(l => l.transactionType.alias),
        // pub_unitType: listingTypes.map(l => l.transactionType.unitType),
      }
      : {};
  };

  const priceSearchParams = priceParam => {
    const inSubunits = value => convertUnitToSubUnit(value, unitDivisor(config.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
        price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
      }
      : {};
  };

  const datesSearchParams = datesParam => {
    const searchTZ = 'CET';
    const datesFilter = config.search.defaultFilters.find(f => f.key === 'dates');
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesFilter && datesParam && values.length === 2;
    const { dateRangeMode, availability } = datesFilter || {};
    const isNightlyMode = dateRangeMode === 'night';
    const isEntireRangeAvailable = availability === 'time-full';

    // SearchPage need to use a single time zone but listings can have different time zones
    // We need to expand/prolong the time window (start & end) to cover other time zones too.
    //
    // NOTE: you might want to consider changing UI so that
    //   1) location is always asked first before date range
    //   2) use some 3rd party service to convert location to time zone (IANA tz name)
    //   3) Make exact dates filtering against that specific time zone
    //   This setup would be better for dates filter,
    //   but it enforces a UX where location is always asked first and therefore configurability
    const getProlongedStart = date => subtractTime(date, 0, 'hours', searchTZ);
    const getProlongedEnd = date => subtractTime(date, 0, 'hours', searchTZ);

    const startDate = hasValues ? parseDateFromISO8601(values[0], searchTZ) : null;
    const endRaw = hasValues ? parseDateFromISO8601(values[1], searchTZ) : null;
    const endDate =
      hasValues && isNightlyMode
        ? endRaw
        : hasValues
          ? getExclusiveEndDate(endRaw, searchTZ)
          : null;

    const today = getStartOf(new Date(), 'day', searchTZ);
    const possibleStartDate = subtractTime(today, 14, 'hours', searchTZ);
    const hasValidDates =
      hasValues &&
      startDate.getTime() >= possibleStartDate.getTime() &&
      startDate.getTime() <= endDate.getTime();

    const dayCount = isEntireRangeAvailable ? daysBetween(startDate, endDate) : 1;
    const day = 1440;
    const hour = 60;
    // When entire range is required to be available, we count minutes of included date range,
    // but there's a need to subtract one hour due to possibility of daylight saving time.
    // If partial range is needed, then we just make sure that the shortest time unit supported
    // is available within the range.
    // You might want to customize this to match with your time units (e.g. day: 1440 - 60)

    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();

    const isTodaySelected = moment(now).format('YYYY-MM-DD') === moment(values[0]).format('YYYY-MM-DD')

    const startDateAndTime = isTodaySelected ?
      moment(values[0], 'YYYY-MM-DD').set("hour", hours).set("minute", minutes).toDate()
      : moment(values[0], 'YYYY-MM-DD').toDate();

    const endDateAndTime = moment(values[1], 'YYYY-MM-DD').add(23, 'hours').add(59, 'minutes').toDate();

    // const minDuration = isEntireRangeAvailable ? dayCount * day - hour : hour;
    const minDuration = 15;
    return hasValidDates
      ? {
        // start: getProlongedStart(startDate),
        // end: getProlongedEnd(endDate),

        start: startDateAndTime,
        end: endDateAndTime,

        // Availability can be time-full or time-partial.
        // However, due to prolonged time window, we need to use time-partial.
        availability: 'time-partial',
        // minDuration uses minutes
        minDuration,
      }
      : {};
  };

  const { perPage, price, dates, sort, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);
  const sortMaybe = sort === config.search.sortConfig.relevanceKey ? {} : { sort };

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    ...sortMaybe,
    ...searchValidListingTypes(config.listing.listingTypes),
    perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, "CET");

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots, listingId));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const fetchMonthlyTimeSlotsSearchPage = (listing) => (dispatch) => {
  const hasWindow = typeof window !== 'undefined';
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone = listing?.attributes?.availabilityPlan?.timezone

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    // const tz = listing.attributes.availabilityPlan.timezone;
    const tz = "CET";
    const nextBoundary = findNextQuarterBoundary(tz, new Date());

    const nextMonth = nextMonthFn(nextBoundary, tz);
    const nextAfterNextMonth = nextMonthFn(nextMonth, tz);

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      // dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }
  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({
  orderData,
  listingId,
  isOwnListing,
  selectedPriceType,
  currentCurrency,
  listingCurrency
}) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({
    orderData,
    listingId,
    isOwnListing,
    selectedPriceType,
    currentCurrency,
    listingCurrency
  })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

export const getAdminProfile = () => (dispatch, getState, sdk) => {
  dispatch(getAdminProfileRequest())
  return sdk.users.show({ id: ADMIN_ID })
    .then(res => dispatch(getAdminProfileSuccess(res?.data?.data)))
    .catch(e => dispatch(getAdminProfileError(e?.message)))
}


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 1 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;


  return Promise.all([
    dispatch(getAdminProfile()),
    dispatch(searchListings(
      {
        ...minStockMaybe,
        ...rest,
        ...originMaybe,
        page,
        perPage: RESULT_PAGE_SIZE,
        include: ['author', 'images'],
        'fields.listing': [
          'title',
          'geolocation',
          'price',
          'availabilityPlan',
          'publicData.listingType',
          'publicData.transactionProcessAlias',
          'publicData.unitType',
          // These help rendering of 'purchase' listings,
          // when transitioning from search page to listing page
          'publicData.pickupEnabled',
          'publicData.shippingEnabled',
          'publicData.location',
          'publicData.typeOfScaner',
          'publicData.currency',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': [
          'variants.scaled-small',
          'variants.scaled-medium',
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
        'limit.images': 10,
      },
      config
    )),
  ])
};
