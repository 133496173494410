import React from 'react';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  autocompletePlaceSelected,
  composeValidators,
} from '../../util/validators';
import {
  Form,
  FieldLocationAutocompleteInput,
  Button,
  IconArrowHead,
  OutsideClickHandler,
  IconScaner,
  IconSearch,
  IconSearchRadius
} from '../../components';
import defaultConfig from '../../config/configDefault';
import { languagesHelper as language } from '../../helpers/languages';

import css from './AdvancedSearch.module.css';

const identity = v => v;

export const AdvancedSearchForm = props => (
  <FinalForm
    mutators={{ ...arrayMutators }}
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        intl,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        onSubmit,
        getValues,
        onClearLocation,
        currentPage,
        updateSelectValue,
        applySelectFilter,
        selectFilterOpen,
        selectValue,
        selectedFilters,
        handleLocationChange,

        updateRadiusValue,
        applyRadiusFilter,
        radiusFilterOpen,
        radiusValue
      } = formRenderProps;

      const addressNotRecognizedMessage = intl.formatMessage({
        id: 'EditListingLocationForm.addressNotRecognized',
      });

      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      // const submitDisabled = invalid || disabled || submitInProgress;
      const submitDisabled = submitInProgress || !values?.location?.selectedPlace;

      const currentLocationSelected = !!values?.location?.selectedPlace?.origin;
      const scanerType = !!selectedFilters?.pub_typeOfScaner ? defaultConfig.typeOfScanerOptions.filter(item => item.key === selectedFilters?.pub_typeOfScaner)?.[0] : null;
      const radius = !!selectedFilters?.pub_locationRadius ? defaultConfig.radiusOptions.filter(item => +item.key === selectedFilters?.pub_locationRadius)?.[0] : null;

      return (
        <Form className={css.rootForm} onSubmit={(e) => { e.preventDefault(), onSubmit }}>

          <FormSpy onChange={handleLocationChange} subscription={{ values: true, dirty: true }} />

          <FieldLocationAutocompleteInput
            rootClassName={css.locationAddress}
            inputClassName={classNames(css.locationAutocompleteInput, { [css.locationAutocompleteInputActive]: currentLocationSelected && currentPage === "SearchPage" })}
            iconClassName={css.locationAutocompleteInputIcon}
            validClassName={css.validLocation}
            autoFocus={false}
            name="location"
            placeholder={intl.formatMessage({
              id: 'AdvancedSearch.locationPlaceholder',
            })}
            useDefaultPredictions={false}
            format={identity}
            valueFromForm={values.location}
            validate={composeValidators(
              autocompletePlaceSelected(addressNotRecognizedMessage)
            )}
            showClearButton={!!onClearLocation && currentLocationSelected && currentPage === "SearchPage"}
            onClearLocation={onClearLocation}
            css={css}
          />

          <OutsideClickHandler onOutsideClick={() => applySelectFilter(false)} className={css.select}>
            <div className={classNames({ [css.selectOpen]: selectFilterOpen }, { [css.selectClosed]: !selectFilterOpen })}>
              <div
                onClick={() => applySelectFilter(!selectFilterOpen)}
                className={
                  classNames(
                    css.selectLabel,
                    { [css.selectLabelActive]: !!scanerType?.key || !!selectValue }
                  )}
              >
                <span className={classNames(css.selectLabelText, { [css.selectLabelTextActive]: !!selectValue || !!scanerType })}>
                  <IconScaner />
                  <span>
                    {!!selectValue || !!scanerType ? <>{selectValue?.label || scanerType?.label}</> : <FormattedMessage id="AdvancedSearch.scanPlaceholder" />}
                  </span>
                </span>
                <IconArrowHead direction={selectFilterOpen ? "up" : "down"} size="big" />
              </div>


              <div className={css.selectMenuContent}>
                {defaultConfig.typeOfScanerOptions.map(item => {
                  return (
                    <div
                      key={item.key}
                      className={css.selectMenuContentItem}
                      onClick={() => updateSelectValue(item)}
                    >
                      {language.labelsTranslator(item.key, intl)}
                      {/* {item.label} */}
                    </div>
                  )
                })}
              </div>
            </div>
          </OutsideClickHandler>
          
          <OutsideClickHandler onOutsideClick={() => applyRadiusFilter(false)} className={classNames(css.select, css.selectRadius)}>
            <div className={classNames({ [css.selectOpen]: radiusFilterOpen }, { [css.selectClosed]: !radiusFilterOpen })}>
              <div
                onClick={() => applyRadiusFilter(!radiusFilterOpen)}
                className={
                  classNames(
                    css.selectLabel,
                    { [css.selectLabelActive]: !!radius?.key || !!radiusValue }
                  )}
              >
                <span className={classNames(css.selectLabelText, { [css.selectLabelTextActive]: !!radiusValue || !!radius })}>
                  <IconSearchRadius />
                  <span>
                    {!!radiusValue || !!radius ? <>{radiusValue?.label || radius?.label}</> : <FormattedMessage id="AdvancedSearch.radiusPlaceholder" />}
                  </span>
                </span>
                <IconArrowHead direction={radiusFilterOpen ? "up" : "down"} size="big" />
              </div>


              <div className={css.selectMenuContent}>
                {defaultConfig.radiusOptions.map(item => {
                  return (
                    <div
                      key={item.key}
                      className={css.selectMenuContentItem}
                      onClick={() => updateRadiusValue(item)}
                    >
                      {item.label}
                    </div>
                  )
                })}
              </div>
            </div>
          </OutsideClickHandler>

          <Button
            className={css.submitButton}
            type="button"
            inProgress={submitInProgress}
            ready={submitReady}
            disabled={submitDisabled}
            onClick={() => getValues(values)}
          >
            <IconSearch className={css.submitButtonIcon} />
            <FormattedMessage id="AdvancedSearch.search" />
          </Button>

        </Form>
      );
    }}
  />
);

export default compose(injectIntl)(AdvancedSearchForm);
