import React, { Component } from 'react';
import { array, bool, func, number, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';

import { propTypes } from '../../util/types';
import _ from 'lodash';
import {
  Form,
  FieldTextInput,
  IconAdd,
  IconDelete,
  FieldRadioButton
} from '../../components';
import * as validators from '../../util/validators';
import css from './BookingEmailsForm.module.css';


export class BookingEmailsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  handleFormSubmit() {
    console.log("Form submited");
  }

  render() {
    const {
      rootClassName,
      className,
      marketplaceName,
      ...rest
    } = this.props;
    
    return (
      <FinalForm
        {...rest}
        mutators={{ ...arrayMutators }}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            intl,
            values,
            invalid,
            setFormValues,
            isCardView,
            form,
            form: {
              mutators: { push, remove }
            }
          } = fieldRenderProps;

          const deleteItem = (valueType, index) => {
            remove(valueType, index);
          };

          const emailRequiredMessage = intl.formatMessage({
            id: 'LoginForm.emailRequired',
          });
          const emailRequired = validators.required(emailRequiredMessage);
          const emailInvalidMessage = intl.formatMessage({
            id: 'LoginForm.emailInvalid',
          });
          const emailValid = validators.emailFormatValid(emailInvalidMessage);

          const classes = classNames(rootClassName || css.root, className, { [css.invalid]: invalid }, { [css.rootCardView]: isCardView });

          const onClearEmails = () => {
            form.change("email", null);
            form.change("additionalEmails", null);
          }

          return (
            <Form onSubmit={this.handleFormSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
              <FormSpy
                subscription={{ values: true }}
                onChange={values => {
                  setFormValues(values)
                }}
              />

              <div className={css.emailsHolder}>
                <label className={css.emailsLabel}>
                  <FormattedMessage id="BookingTimeForm.receivingDentistEmail" />
                  {/* <span className={css.grayText}>
                    <FormattedMessage id="BookingTimeForm.addTo3emails" />
                  </span> */}
                </label>

                <FieldRadioButton
                  label={intl.formatMessage({ id: 'BookingTimeForm.emailYesLabel' })}
                  id="sendEmailsYes"
                  name="sendEmails"
                  value="sendEmailsYes"
                />

                <div onClick={() => onClearEmails()}>
                  <FieldRadioButton
                    label={intl.formatMessage({ id: 'BookingTimeForm.emailNoLabel' })}
                    id="sendEmailsNo"
                    name="sendEmails"
                    value="sendEmailsNo"
                  />
                </div>

                {values?.sendEmails === "sendEmailsYes" && (
                  <>
                    <div className={css.emailsItem}>
                      <FieldTextInput
                        id="email"
                        name="email"
                        className={classNames(css.serviceBlockInput, css.requiredLabel)}
                        type="email"
                        placeholder={intl.formatMessage({ id: 'BookingTimeForm.enterEmail' })}
                        validate={validators.composeValidators(emailRequired, emailValid)}
                        autoComplete="test"
                      />
                      {values?.additionalEmails?.length >= 2 ? (
                        <span className={css.spaceHolder} />
                      ) : (
                        <button
                          className={css.emailsAddBtn}
                          type="button"
                          onClick={() => push('additionalEmails', null)}
                        >
                          <IconAdd size="big" />
                        </button>
                      )}
                    </div>

                    <FieldArray name={'additionalEmails'}>
                      {({ fields }) => (
                        <>
                          {fields.map((name, i) => (

                            <div key={`${name}`} className={css.emailsItem}>
                              <FieldTextInput
                                id={`${name}`}
                                name={`${name}`}
                                className={classNames(css.serviceBlockInput, css.requiredLabel)}
                                type="email"
                                placeholder={intl.formatMessage({ id: 'BookingTimeForm.enterEmail' })}
                                validate={validators.composeValidators(emailRequired, emailValid)}
                                autoComplete="test"
                              />
                              <button
                                className={css.emailsDeleteBtn}
                                type="button"
                                onClick={() => { deleteItem('additionalEmails', i) }}
                              >
                                <IconDelete size="big" />
                              </button>
                            </div>

                          ))}

                        </>
                      )}
                    </FieldArray>
                  </>
                )}
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

BookingEmailsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingEmailsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  marketplaceName: string.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,
  onFetchTimeSlots: func.isRequired,
  timeZone: string.isRequired,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,

};

const BookingEmailsForm = compose(injectIntl)(BookingEmailsFormComponent);
BookingEmailsForm.displayName = 'BookingEmailsForm';

export default BookingEmailsForm;
