import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconGlobe.module.css';

const IconGlobe = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fillRule="evenodd"
      clipRule="evenodd"
    >
      <path d="M12.02 0C18.634.011 24 5.383 24 12c0 6.623-5.376 12-12 12-6.623 0-12-5.377-12-12C0 5.383 5.367.011 11.981 0h.039zm3.694 16H8.287c.639 4.266 2.242 7 3.713 7 1.472 0 3.075-2.734 3.714-7m6.535 0h-5.523c-.426 2.985-1.321 5.402-2.485 6.771A11.025 11.025 0 0022.249 16M7.275 16H1.751a11.029 11.029 0 008.009 6.771C8.596 21.402 7.701 18.985 7.275 16m-.123-7H1.416a11.043 11.043 0 000 6h5.736a29.82 29.82 0 010-6m8.691 0H8.158a28.617 28.617 0 000 6h7.685a28.62 28.62 0 000-6m6.742 0h-5.736c.062.592.308 3.019 0 6h5.736a11.042 11.042 0 000-6M9.76 1.229A11.029 11.029 0 001.751 8h5.524c.426-2.985 1.321-5.403 2.485-6.771M15.714 8C15.075 3.734 13.472 1 12 1c-1.471 0-3.074 2.734-3.713 7h7.427zm-1.473-6.771C15.405 2.597 16.3 5.015 16.726 8h5.523a11.025 11.025 0 00-8.008-6.771"></path>
    </svg>
  );
};

IconGlobe.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconGlobe.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconGlobe;
