import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconAdd.module.css';

const IconAdd = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === "big") {
    return (
      <svg
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        width="28"
        height="28"
        fill="none"
        viewBox="0 0 28 28"
      >
        <path
          fill="#4A4A4A"
          d="M24.386 14.008a1.161 1.161 0 00-1.163-1.163l-8.06-.008V4.769a1.162 1.162 0 10-2.326 0v8.068H4.769a1.164 1.164 0 000 2.326h8.068v8.068a1.162 1.162 0 002.326 0v-8.068h8.068a1.17 1.17 0 001.155-1.155z"
        ></path>
      </svg>
    )
  } else {
    return (
      <svg className={classes} width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.89 10.4V6.888h3.509a.889.889 0 1 0 0-1.779H6.89V1.6a.89.89 0 0 0-1.778 0v3.511h-3.51a.888.888 0 1 0 0 1.778h3.51v3.51a.889.889 0 1 0 1.778 0"
          fillRule="evenodd"
        />
      </svg>
    );
  }

};

const { string } = PropTypes;

IconAdd.defaultProps = {
  className: null,
  rootClassName: null,
};

IconAdd.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconAdd;
