import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import css from './LoginPopupContent.module.css';


const LoginPopupContent = props => {

  const routeConfiguration = useRouteConfiguration();
  const history = useHistory();
  const location = useLocation();

  const handleGoToSignupPage = () => {

    const state = { from: `${location.pathname}${location.search}${location.hash}` };
    // signup and return back to search/listingPage.
    history.push(createResourceLocatorString('LoginPage', routeConfiguration, {}, {}), state);
  }


  return (
    <div className={css.loginPopup}>
      <p className={css.loginPopupText}>
        <FormattedMessage id="LoginPopupContent.text" />
      </p>
      <div
        className={css.loginPopupBtn}
        name="SignupPage"
        onClick={() => handleGoToSignupPage()}
      >
        <FormattedMessage id="LoginPopupContent.btn" />
      </div>
    </div>
  );
};

LoginPopupContent.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
  type: null,
};

const { string } = PropTypes;

LoginPopupContent.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
  type: string,
};

export default LoginPopupContent;
