import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconScaner.module.css';

const IconScaner = props => {
  const { className, rootClassName } = props;
  const classes = classNames(rootClassName, className, css.root);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#323232"
        d="M20.318 3.982l-7.964-2.714a1.153 1.153 0 00-.354-.05c-.129 0-.258.017-.354.05L3.682 3.982a.562.562 0 00-.354.497v11.306c0 .206.134.478.295.607l8.08 6.296a.485.485 0 00.295.096.48.48 0 00.295-.096l8.079-6.296a.872.872 0 00.295-.607V4.48a.552.552 0 00-.349-.497zm-1.334 11.353L12 20.777l-6.984-5.442V5.313L12 2.932l6.984 2.381v10.022zm-9.541-2.941L7.627 14.21a.188.188 0 000 .265l.796.797a.188.188 0 00.265 0l1.817-1.816a3.377 3.377 0 10-1.062-1.062zm1.533-3.124a1.874 1.874 0 012.653 0 1.874 1.874 0 010 2.653 1.876 1.876 0 01-2.653-2.653z"
      ></path>
    </svg>
  );
};

IconScaner.defaultProps = { className: null, rootClassName: null };

const { bool, string } = PropTypes;

IconScaner.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconScaner;
