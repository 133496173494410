import * as stripe from './configStripe';
import * as listing from './configListing';
import * as search from './configSearch';
import * as maps from './configMaps';
import * as branding from './configBranding';
import * as layout from './configLayout';

import { defaultLocale } from '../intl';

export const typeOfScanerOptions = [
  { key: "dentalCheckupExam", label: "Dental checkup / exam" },
  { key: "cleaningAndTartarRemoval", label: "Cleaning and tartar removal" },
  { key: "CBCTScanner", label: "CB-CT Scan" },
  { key: "standardXRay", label: "X-ray" },
  { key: "panoramicXRay", label: "Panoramic x-ray" },
  { key: "intraoralScanner", label: "Intraoral, hand-held scanner" },
];

export const dentalCheckupBasicServices = [
  { key: "examineYourTeethGumsMouth", label: "Examine your teeth, gums and mouth" },
  { key: "performedByRegisteredDentist", label: "Performed by a licensed dentist" },
  { key: "standardXrayOrPanoramic", label: "Standard x-ray or panoramic x-ray" },
];

export const cleaningAndTartarRemovalBasicServices = [
  { key: "removalOfPlaqueAndTartar", label: "Removal of plaque and tartar" },
  { key: "cleaningWithProfessionalGrityToothpaste", label: "Cleaning with professional grity toothpaste and high-powered electric toothbrush" },
  { key: "expertToothFlossing", label: "Expert tooth flossing" },
  { key: "rinse", label: "Rinse" },
  { key: "florideTreatmentApplication", label: "Floride treatment application" },
];

export const scanBasicServices = [
  {scan: 'CBCTScanner', options: [{ key: "fullUpperLowerCBCTScan", label: "Full upper and lower CB-CT Scan" }]},
  {scan: 'standardXRay', options: [{ key: "digitalAnalogXrayOfTeeth", label: "Digital or analog x-ray of teeth" }]},
  {scan: 'panoramicXRay', options: [{ key: "fullMouthXray", label: "Full mouth x-ray" }]},
  {scan: 'intraoralScanner', options: [{ key: "fullMouthScan", label: "Full mouth scan" }]},
];

export const radiusOptions = [
  { key: "1", label: "1km" },
  { key: "5", label: "5km" },
  { key: "10", label: "10km" },
  { key: "20", label: "20km" },
  { key: "50", label: "50km" },
  { key: "100", label: "100km" },
  { key: "200", label: "200km" },
  { key: "500", label: "500km" },
];

export const serviceDurationOptions = [
  { key: "15minutes", label: "15 minutes" },
  { key: "30minutes", label: "30 minutes" },
  { key: "45minutes", label: "45 minutes" },
  { key: "60minutes", label: "1 hour" },
  { key: "90minutes", label: "1.5 hour" },
  { key: "120minutes", label: "2 hours" },
];

export const currencyOptions = [
  { key: "CHF", label: "CHF" },
  { key: "EUR", label: "EUR" },
  { key: "USD", label: "USD" },
];

// NOTE: only expose configuration that should be visible in the
// client side, don't add any server secrets to this file.
const defaultConfig = {
  // Marketplace root url is the root or domain, where this app is currently running
  // It is needed in social media sharing and SEO optimization purposes and SSO etc.
  // It should be in environment variables (on localhost 'yarn run config' or edit .env file)
  marketplaceRootURL: process.env.REACT_APP_MARKETPLACE_ROOT_URL,

  // Marketplace currency.
  // The currency used in the Marketplace must be in ISO 4217 currency code. For example USD, EUR, CAD, AUD, etc. The default value is USD.
  // It should match one of the currencies listed in currencySettings.js
  currency: process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY,

  // Listing minimum price in currency sub units, e.g. cents.
  // 0 means no restriction to the price
  // Note 1: hosted configs overwrite this
  // Note 2: Stripe does have minimum fee that depends on country, currency, etc.
  listingMinimumPriceSubUnits: 500,

  // Marketplace name is needed for microcopy and in meta tags (bots and social media sharing reads those)
  marketplaceName: 'Assenz Dental',

  // Modify Stripe configuration in stripeConfig.js
  // - picks REACT_APP_STRIPE_PUBLISHABLE_KEY from environment variables
  // - dayCountAvailableForBooking: Stripe can hold payments only limited time on Connect Account
  //                                This adds some restriction for bookings (payouts vs long bookings)
  // - defaultMCC: sets Default Merchant Category Code
  // - supportedCountries
  stripe,

  // Modify listing extended data and listing type in listingConfig.js
  listing,
  // Modify search settings data in searchConfig.js
  search,
  // Modify settings for map providers in mapsConfig.js
  // This includes also default locations for location search
  maps,
  // Modify branding configs in brandingConfig.js
  branding,
  // Modify layout configs in layoutConfig.js
  layout,

  // Note: Facebook app id is used for Facebook login, but it is also used for tracking:
  // Facebook counts shares with app or page associated by this id
  // Currently it is unset, but you can read more about fb:app_id from
  // https://developers.facebook.com/docs/sharing/webmasters#basic
  // You should create one to track social sharing in Facebook
  facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,

  // If you want to change the language, remember to also change the
  // locale data and the messages in the app.js file.
  localization: {
    locale: defaultLocale,
    // First day of week
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo#return_value
    // 1: Monday
    // ...
    // 6: Saturday
    // 7: Sunday
    firstDayOfWeek: 1,
  },

  // CDN assets for the app. Configurable through Flex Console.
  // Currently, only translation.json is available.
  // Note: the path must match the path defined in Asset Delivery API
  appCdnAssets: {
    translations: '/content/translations.json',
    footer: '/content/footer.json',
    branding: '/design/branding.json',
    layout: '/design/layout.json',
    listingTypes: '/listings/listing-types.json',
    listingFields: '/listings/listing-fields.json',
    search: '/listings/listing-search.json',
    transactionSize: '/transactions/minimum-transaction-size.json',
    // NOTE: we don't fetch commission configuration here but on the server-side

    // TODO: Map provider configuration and analytics service should come from the assets too.
    //       It might take some time before these are actually available through hosted assets.
    // maps: '/integrations/map.json',
    // analytics: '/integrations/analytics.json',
    // googleSearchConsole: '/integrations/google-search-console.json',
    // localization: '/general/localization.json',
  },

  // Optional
  // Online presence of the same organization:
  // Facebook page is used in SEO schema (http://schema.org/Organization)
  siteFacebookPage: null, // e.g. '@sharetribe',
  // Instagram page is used in SEO schema (http://schema.org/Organization)
  siteInstagramPage: null, // e.g. 'https://www.instagram.com/sharetribe/',
  // Twitter handle is needed in meta tags (twitter:site). Start it with '@' character
  siteTwitterHandle: null, // e.g. 'https://www.facebook.com/Sharetribe/',

  // Optional
  // Note that Google Analytics might need advanced opt-out option / cookie consent
  // depending on jurisdiction (e.g. EU countries), since it relies on cookies.
  analytics: {
    googleAnalyticsId: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  },

  // Optional
  // This creates meta tag for Google Search Console verification
  // I.e. <meta name=“google-site-verification” content=“GOOGLE_SITE_VERIFICATION_TOKEN”/>
  googleSearchConsole: {
    googleSiteVerification: null, // Add google-site-verification token as a string
  },

  // Optional
  // Address information is used in SEO schema for Organization (http://schema.org/PostalAddress)
  // Note: Google doesn't recognize this:
  // https://developers.google.com/search/docs/advanced/structured-data/logo#structured-data-type-definitions
  address: {
    addressCountry: null, // 'FI',
    addressRegion: null, // 'Helsinki',
    postalCode: null, // '00130',
    streetAddress: null, // 'Erottajankatu 19 B',
  },

  typeOfScanerOptions: typeOfScanerOptions,
  dentalCheckupBasicServices: dentalCheckupBasicServices,
  cleaningAndTartarRemovalBasicServices: cleaningAndTartarRemovalBasicServices,
  radiusOptions: radiusOptions,
  serviceDurationOptions: serviceDurationOptions,
  scanBasicServices: scanBasicServices,
  currencyOptions: currencyOptions,

  timeSlotMinutes: 15,

  customTimeZonesEn:
    [
      { key: 'CET', label: 'CET' },
      { key: 'CST6CDT', label: 'CST6CDT' },
      { key: 'EET', label: 'EET' },
      { key: 'EST', label: 'EST' },
      { key: 'EST5EDT', label: 'EST5EDT' },
      { key: 'Etc/GMT', label: 'Etc/GMT' },
      { key: 'Etc/Greenwich', label: 'Etc/Greenwich' },
      { key: 'Etc/UCT', label: 'Etc/UCT' },
      { key: 'Etc/UTC', label: 'Etc/UTC' },
      { key: 'Etc/Universal', label: 'Etc/Universal' },
      { key: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
      { key: 'Europe/Andorra', label: 'Europe/Andorra' },
      { key: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
      { key: 'Europe/Athens', label: 'Europe/Athens' },
      { key: 'Europe/Belfast', label: 'Europe/Belfast' },
      { key: 'Europe/Belgrade', label: 'Europe/Belgrade' },
      { key: 'Europe/Berlin', label: 'Europe/Berlin' },
      { key: 'Europe/Bratislava', label: 'Europe/Bratislava' },
      { key: 'Europe/Brussels', label: 'Europe/Brussels' },
      { key: 'Europe/Bucharest', label: 'Europe/Bucharest' },
      { key: 'Europe/Budapest', label: 'Europe/Budapest' },
      { key: 'Europe/Busingen', label: 'Europe/Busingen' },
      { key: 'Europe/Chisinau', label: 'Europe/Chisinau' },
      { key: 'Europe/Copenhagen', label: 'Europe/Copenhagen' },
      { key: 'Europe/Dublin', label: 'Europe/Dublin' },
      { key: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
      { key: 'Europe/Guernsey', label: 'Europe/Guernsey' },
      { key: 'Europe/Helsinki', label: 'Europe/Helsinki' },
      { key: 'Europe/Isle_of_Man', label: 'Europe/Isle_of_Man' },
      { key: 'Europe/Istanbul', label: 'Europe/Istanbul' },
      { key: 'Europe/Jersey', label: 'Europe/Jersey' },
      { key: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
      { key: 'Europe/Kiev', label: 'Europe/Kiev' },
      { key: 'Europe/Lisbon', label: 'Europe/Lisbon' },
      { key: 'Europe/Ljubljana', label: 'Europe/Ljubljana' },
      { key: 'Europe/London', label: 'Europe/London' },
      { key: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
      { key: 'Europe/Madrid', label: 'Europe/Madrid' },
      { key: 'Europe/Malta', label: 'Europe/Malta' },
      { key: 'Europe/Mariehamn', label: 'Europe/Mariehamn' },
      { key: 'Europe/Minsk', label: 'Europe/Minsk' },
      { key: 'Europe/Monaco', label: 'Europe/Monaco' },
      { key: 'Europe/Nicosia', label: 'Europe/Nicosia' },
      { key: 'Europe/Oslo', label: 'Europe/Oslo' },
      { key: 'Europe/Paris', label: 'Europe/Paris' },
      { key: 'Europe/Podgorica', label: 'Europe/Podgorica' },
      { key: 'Europe/Prague', label: 'Europe/Prague' },
      { key: 'Europe/Riga', label: 'Europe/Riga' },
      { key: 'Europe/Rome', label: 'Europe/Rome' },
      { key: 'Europe/San_Marino', label: 'Europe/San_Marino' },
      { key: 'Europe/Sarajevo', label: 'Europe/Sarajevo' },
      { key: 'Europe/Simferopol', label: 'Europe/Simferopol' },
      { key: 'Europe/Skopje', label: 'Europe/Skopje' },
      { key: 'Europe/Sofia', label: 'Europe/Sofia' },
      { key: 'Europe/Stockholm', label: 'Europe/Stockholm' },
      { key: 'Europe/Tallinn', label: 'Europe/Tallinn' },
      { key: 'Europe/Tirane', label: 'Europe/Tirane' },
      { key: 'Europe/Tiraspol', label: 'Europe/Tiraspol' },
      { key: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
      { key: 'Europe/Vaduz', label: 'Europe/Vaduz' },
      { key: 'Europe/Vatican', label: 'Europe/Vatican' },
      { key: 'Europe/Vienna', label: 'Europe/Vienna' },
      { key: 'Europe/Vilnius', label: 'Europe/Vilnius' },
      { key: 'Europe/Warsaw', label: 'Europe/Warsaw' },
      { key: 'Europe/Zagreb', label: 'Europe/Zagreb' },
      { key: 'Europe/Zaporozhye', label: 'Europe/Zaporozhye' },
      { key: 'Europe/Zurich', label: 'Europe/Zurich' },
      { key: 'GMT', label: 'GMT' },
      { key: 'GMT0', label: 'GMT0' },
      { key: 'HST', label: 'HST' },
      { key: 'MET', label: 'MET' },
      { key: 'MST', label: 'MST' },
      { key: 'MST7MDT', label: 'MST7MDT' },
      { key: 'PRC', label: 'PRC' },
      { key: 'PST8PDT', label: 'PST8PDT' },
      { key: 'ROC', label: 'ROC' },
      { key: 'ROK', label: 'ROK' },
      { key: 'UCT', label: 'UCT' },
      { key: 'UTC', label: 'UTC' },
      { key: 'Universal', label: 'Universal' },
      { key: 'W-SU', label: 'W-SU' },
      { key: 'WET', label: 'WET' }
    ],

  customTimeZonesFr:
    [
      { key: 'CET', label: 'CET' },
      { key: 'CST6CDT', label: 'CST6CDT' },
      { key: 'EET', label: 'EET' },
      { key: 'EST', label: 'EST' },
      { key: 'EST5EDT', label: 'EST5EDT' },
      { key: 'Etc/GMT', label: 'Etc/GMT' },
      { key: 'Etc/Greenwich', label: 'Etc/Greenwich' },
      { key: 'Etc/UCT', label: 'Etc/UCT' },
      { key: 'Etc/UTC', label: 'Etc/UTC' },
      { key: 'Etc/Universal', label: 'Etc/Universal' },
      { key: 'Europe/Amsterdam', label: 'Europe/Amsterdam' },
      { key: 'Europe/Andorra', label: 'Europe/Andorre' },
      { key: 'Europe/Astrakhan', label: 'Europe/Astrakhan' },
      { key: 'Europe/Athens', label: 'Europe/Athènes' },
      { key: 'Europe/Belfast', label: 'Europe/Belfast' },
      { key: 'Europe/Belgrade', label: 'Europe/Belgrade' },
      { key: 'Europe/Berlin', label: 'Europe/Berlin' },
      { key: 'Europe/Bratislava', label: 'Europe/Bratislava' },
      { key: 'Europe/Brussels', label: 'Europe/Bruxelles' },
      { key: 'Europe/Bucharest', label: 'Europe/Bucarest' },
      { key: 'Europe/Budapest', label: 'Europe/Budapest' },
      { key: 'Europe/Busingen', label: 'Europe/Busingen' },
      { key: 'Europe/Chisinau', label: 'Europe/Chisinau' },
      { key: 'Europe/Copenhagen', label: 'Europe/Copenhague' },
      { key: 'Europe/Dublin', label: 'Europe/Dublin' },
      { key: 'Europe/Gibraltar', label: 'Europe/Gibraltar' },
      { key: 'Europe/Guernsey', label: 'Europe/Guernesey' },
      { key: 'Europe/Helsinki', label: 'Europe/Helsinki' },
      { key: 'Europe/Isle_of_Man', label: 'Europe/Île de Man' },
      { key: 'Europe/Istanbul', label: 'Europe/Istanbul' },
      { key: 'Europe/Jersey', label: 'Europe/Jersey' },
      { key: 'Europe/Kaliningrad', label: 'Europe/Kaliningrad' },
      { key: 'Europe/Kiev', label: 'Europe/Kiev' },
      { key: 'Europe/Lisbon', label: 'Europe/Lisbonne' },
      { key: 'Europe/Ljubljana', label: 'Europe/Ljubljana' },
      { key: 'Europe/London', label: 'Europe/Londres' },
      { key: 'Europe/Luxembourg', label: 'Europe/Luxembourg' },
      { key: 'Europe/Madrid', label: 'Europe/Madrid' },
      { key: 'Europe/Malta', label: 'Europe/Malte' },
      { key: 'Europe/Mariehamn', label: 'Europe/Mariehamn' },
      { key: 'Europe/Minsk', label: 'Europe/Minsk' },
      { key: 'Europe/Monaco', label: 'Europe/Monaco' },
      { key: 'Europe/Nicosia', label: 'Europe/Nicosie' },
      { key: 'Europe/Oslo', label: 'Europe/Oslo' },
      { key: 'Europe/Paris', label: 'Europe/Paris' },
      { key: 'Europe/Podgorica', label: 'Europe/Podgorica' },
      { key: 'Europe/Prague', label: 'Europe/Prague' },
      { key: 'Europe/Riga', label: 'Europe/Riga' },
      { key: 'Europe/Rome', label: 'Europe/Rome' },
      { key: 'Europe/San_Marino', label: 'Europe/Saint-Marin' },
      { key: 'Europe/Sarajevo', label: 'Europe/Sarajevo' },
      { key: 'Europe/Simferopol', label: 'Europe/Simferopol' },
      { key: 'Europe/Skopje', label: 'Europe/Skopje' },
      { key: 'Europe/Sofia', label: 'Europe/Sofia' },
      { key: 'Europe/Stockholm', label: 'Europe/Stockholm' },
      { key: 'Europe/Tallinn', label: 'Europe/Tallinn' },
      { key: 'Europe/Tirane', label: 'Europe/Tirana' },
      { key: 'Europe/Tiraspol', label: 'Europe/Tiraspol' },
      { key: 'Europe/Uzhgorod', label: 'Europe/Uzhgorod' },
      { key: 'Europe/Vaduz', label: 'Europe/Vaduz' },
      { key: 'Europe/Vatican', label: 'Europe/Vatican' },
      { key: 'Europe/Vienna', label: 'Europe/Vienne' },
      { key: 'Europe/Vilnius', label: 'Europe/Vilnius' },
      { key: 'Europe/Warsaw', label: 'Europe/Varsovie' },
      { key: 'Europe/Zagreb', label: 'Europe/Zagreb' },
      { key: 'Europe/Zaporozhye', label: 'Europe/Zaporojie' },
      { key: 'Europe/Zurich', label: 'Europe/Zurich' },
      { key: 'GMT', label: 'GMT' },
      { key: 'GMT0', label: 'GMT0' },
      { key: 'HST', label: 'HST' },
      { key: 'MET', label: 'MET' },
      { key: 'MST', label: 'MST' },
      { key: 'MST7MDT', label: 'MST7MDT' },
      { key: 'PRC', label: 'PRC' },
      { key: 'PST8PDT', label: 'PST8PDT' },
      { key: 'ROC', label: 'ROC' },
      { key: 'ROK', label: 'ROK' },
      { key: 'UCT', label: 'UCT' },
      { key: 'UTC', label: 'UTC' },
      { key: 'Universal', label: 'Universel' },
      { key: 'W-SU', label: 'W-SU' },
      { key: 'WET', label: 'WET' }
    ],

  countriesListEn: [
    { key: 'AL', label: 'Albania' },
    { key: 'DZ', label: 'Algeria' },
    { key: 'AR', label: 'Argentina' },
    { key: 'AM', label: 'Armenia' },
    { key: 'AT', label: 'Austria' },
    { key: 'BD', label: 'Bangladesh' },
    { key: 'BE', label: 'Belgium' },
    { key: 'BA', label: 'Bosnia and Herzegovina' },
    { key: 'BR', label: 'Brazil' },
    { key: 'BG', label: 'Bulgaria' },
    { key: 'CA', label: 'Canada' },
    { key: 'CL', label: 'Chile' },
    { key: 'CO', label: 'Colombia' },
    { key: 'CR', label: 'Costa Rica' },
    { key: 'HR', label: 'Croatia' },
    { key: 'CZ', label: 'Czech Republic' },
    { key: 'DK', label: 'Denmark' },
    { key: 'EC', label: 'Ecuador' },
    { key: 'SV', label: 'El Salvador' },
    { key: 'EE', label: 'Estonia' },
    { key: 'FI', label: 'Finland' },
    { key: 'FR', label: 'France' },
    { key: 'DE', label: 'Germany' },
    { key: 'GR', label: 'Greece' },
    { key: 'GT', label: 'Guatemala' },
    { key: 'HN', label: 'Honduras' },
    { key: 'HU', label: 'Hungary' },
    { key: 'IN', label: 'India' },
    { key: 'ID', label: 'Indonesia' },
    { key: 'IE', label: 'Ireland' },
    { key: 'IL', label: 'Israel' },
    { key: 'IT', label: 'Italy' },
    { key: 'JP', label: 'Japan' },
    { key: 'JO', label: 'Jordan' },
    { key: 'LV', label: 'Latvia' },
    { key: 'LB', label: 'Lebanon' },
    { key: 'LT', label: 'Lithuania' },
    { key: 'LU', label: 'Luxembourg' },
    { key: 'MY', label: 'Malaysia' },
    { key: 'MX', label: 'Mexico' },
    { key: 'MC', label: 'Monaco' },
    { key: 'ME', label: 'Montenegro' },
    { key: 'MA', label: 'Morocco' },
    { key: 'NL', label: 'Netherlands' },
    { key: 'NI', label: 'Nicaragua' },
    { key: 'MK', label: 'North Macedonia' },
    { key: 'NO', label: 'Norway' },
    { key: 'PK', label: 'Pakistan' },
    { key: 'PA', label: 'Panama' },
    { key: 'PY', label: 'Paraguay' },
    { key: 'PE', label: 'Peru' },
    { key: 'PH', label: 'Philippines' },
    { key: 'PL', label: 'Poland' },
    { key: 'PT', label: 'Portugal' },
    { key: 'RO', label: 'Romania' },
    { key: 'RS', label: 'Serbia' },
    { key: 'SG', label: 'Singapore' },
    { key: 'SK', label: 'Slovakia' },
    { key: 'SI', label: 'Slovenia' },
    { key: 'ZA', label: 'South Africa' },
    { key: 'KR', label: 'South Korea' },
    { key: 'ES', label: 'Spain' },
    { key: 'LK', label: 'Sri Lanka' },
    { key: 'SE', label: 'Sweden' },
    { key: 'CH', label: 'Switzerland' },
    { key: 'TH', label: 'Thailand' },
    { key: 'TN', label: 'Tunisia' },
    { key: 'TR', label: 'Turkey' },
    { key: 'UG', label: 'Uganda' },
    { key: 'UA', label: 'Ukraine' },
    { key: 'GB', label: 'United Kingdom' },
    { key: 'US', label: 'United States of America' },
    { key: 'UY', label: 'Uruguay' },
    { key: 'VE', label: 'Venezuela' },
    { key: 'VN', label: 'Vietnam' }
  ],

  countriesListFr: [
    { key: 'AL', label: 'Albanie' },
    { key: 'DZ', label: 'Algérie' },
    { key: 'AR', label: 'Argentine' },
    { key: 'AM', label: 'Arménie' },
    { key: 'AT', label: 'Autriche' },
    { key: 'BD', label: 'Bangladesh' },
    { key: 'BE', label: 'Belgique' },
    { key: 'BA', label: 'Bosnie-Herzégovine' },
    { key: 'BR', label: 'Brésil' },
    { key: 'BG', label: 'Bulgarie' },
    { key: 'CA', label: 'Canada' },
    { key: 'CL', label: 'Chili' },
    { key: 'CO', label: 'Colombie' },
    { key: 'CR', label: 'Costa Rica' },
    { key: 'HR', label: 'Croatie' },
    { key: 'CZ', label: 'République tchèque' },
    { key: 'DK', label: 'Danemark' },
    { key: 'EC', label: 'Équateur' },
    { key: 'SV', label: 'Salvador' },
    { key: 'EE', label: 'Estonie' },
    { key: 'FI', label: 'Finlande' },
    { key: 'FR', label: 'France' },
    { key: 'DE', label: 'Allemagne' },
    { key: 'GR', label: 'Grèce' },
    { key: 'GT', label: 'Guatemala' },
    { key: 'HN', label: 'Honduras' },
    { key: 'HU', label: 'Hongrie' },
    { key: 'IN', label: 'Inde' },
    { key: 'ID', label: 'Indonésie' },
    { key: 'IE', label: 'Irlande' },
    { key: 'IL', label: 'Israël' },
    { key: 'IT', label: 'Italie' },
    { key: 'JP', label: 'Japon' },
    { key: 'JO', label: 'Jordanie' },
    { key: 'LV', label: 'Lettonie' },
    { key: 'LB', label: 'Liban' },
    { key: 'LT', label: 'Lituanie' },
    { key: 'LU', label: 'Luxembourg' },
    { key: 'MY', label: 'Malaisie' },
    { key: 'MX', label: 'Mexique' },
    { key: 'MC', label: 'Monaco' },
    { key: 'ME', label: 'Monténégro' },
    { key: 'MA', label: 'Maroc' },
    { key: 'NL', label: 'Pays-Bas' },
    { key: 'NI', label: 'Nicaragua' },
    { key: 'MK', label: 'Macédoine du Nord' },
    { key: 'NO', label: 'Norvège' },
    { key: 'PK', label: 'Pakistan' },
    { key: 'PA', label: 'Panama' },
    { key: 'PY', label: 'Paraguay' },
    { key: 'PE', label: 'Pérou' },
    { key: 'PH', label: 'Philippines' },
    { key: 'PL', label: 'Pologne' },
    { key: 'PT', label: 'Portugal' },
    { key: 'RO', label: 'Roumanie' },
    { key: 'RS', label: 'Serbie' },
    { key: 'SG', label: 'Singapour' },
    { key: 'SK', label: 'Slovaquie' },
    { key: 'SI', label: 'Slovénie' },
    { key: 'ZA', label: 'Afrique du Sud' },
    { key: 'KR', label: 'Corée du Sud' },
    { key: 'ES', label: 'Espagne' },
    { key: 'LK', label: 'Sri Lanka' },
    { key: 'SE', label: 'Suède' },
    { key: 'CH', label: 'Suisse' },
    { key: 'TH', label: 'Thaïlande' },
    { key: 'TN', label: 'Tunisie' },
    { key: 'TR', label: 'Turquie' },
    { key: 'UG', label: 'Ouganda' },
    { key: 'UA', label: 'Ukraine' },
    { key: 'GB', label: 'Royaume-Uni' },
    { key: 'US', label: 'États-Unis d\'Amérique\'' },
    { key: 'UY', label: 'Uruguay' },
    { key: 'VE', label: 'Venezuela' },
    { key: 'VN', label: 'Vietnam' }
  ],

  dentalAssociationsOptions: [
    { key: "osterreichischeZahnarztekammer", label: "Österreichische Zahnärztekammer" },
    { key: "csdChambreSyndicaleDentaire", label: "CSD - Chambre Syndicale Dentaire" },
    { key: "vvtVerbondDerVlaamseTandartsen", label: "VVT - Verbond der Vlaamse Tandartsen" },
    { key: "bulgarianDentalAssociation", label: "Bulgarian Dental Association" },
    { key: "croatianDentalChamber", label: "Croatian Dental Chamber" },
    { key: "cyprusDentalAssociation", label: "Cyprus Dental Association" },
    { key: "czechDentalChamber", label: "Czech Dental Chamber" },
    { key: "danishDentalAssociation", label: "Danish Dental Association" },
    { key: "estonianDentalAssociation", label: "Estonian Dental Association" },
    { key: "finnishDentalAssociation", label: "Finnish Dental Association" },
    { key: "associationDentaireFrançaise", label: "Association Dentaire Française" },
    { key: "bundeszahnarztekammer", label: "Bundeszahnärztekammer" },
    { key: "hellenicDentalAssociation", label: "Hellenic Dental Association" },
    { key: "nationalCommitteeForHungarianDentistry", label: "National Committee for Hungarian Dentistry" },
    { key: "icelandicDentalAssociation", label: "Icelandic Dental Association" },
    { key: "irishDentalAssociation", label: "Irish Dental Association" },
    { key: "associazioneNazionaleDentistiItaliani", label: "Associazione Nazionale Dentisti Italiani" },
    { key: "latvianDentalAssociation", label: "Latvian Dental Association" },
    { key: "lithuanianDentalChamber", label: "Lithuanian Dental Chamber" },
    { key: "cercleDesMedecinsDentistesDuGrandDucheDeLuxembourg", label: "Cercle des Médecins-Dentistes du Grand-Duché de Luxembourg" },
    { key: "dentalAssociationOfMalta", label: "Dental Association of Malta" },
    { key: "royalDutchDentalAssociation", label: "Royal Dutch Dental Association" },
    { key: "norwegianDentalAssociation", label: "Norwegian Dental Association" },
    { key: "polishChamberOfPhysiciansAndDentists", label: "Polish Chamber of Physicians and Dentists" },
    { key: "portugueseDentalAssociation", label: "Portuguese Dental Association" },
    { key: "romanianDentalAssociationOfPrivatePractitioners", label: "Romanian Dental Association of Private Practitioners" },
    { key: "slovakChamberOfDentists", label: "Slovak Chamber of Dentists" },
    { key: "theMedicalChamberOfSlovenia", label: "The Medical Chamber of Slovenia" },
    { key: "spanishDentalCouncil", label: "Spanish Dental Council" },
    { key: "swedishDentalAssociation", label: "Swedish Dental Association" },
    { key: "swissDentalAssociation", label: "Swiss Dental Association" },
    { key: "britishDentalAssociation", label: "British Dental Association" },
    { key: "americanDentalAssociation", label: "American Dental Association" },
    { key: "other", label: "Other" }
  ],

};

export default defaultConfig;
