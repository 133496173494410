import React, { useEffect } from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';
import ReactImageGallery from 'react-image-gallery';
import { useConfiguration } from '../../context/configurationContext';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { propTypes, isPatientRole, isDentistRole } from '../../util/types';
import { ensureListing, getDistanceFromLatLonInKm } from '../../util/data';
import { createSlug } from '../../util/urlHelpers';
import { types } from 'sharetribe-flex-sdk';
import defaultConfig from '../../config/configDefault';
import _ from 'lodash';
import {
  ResponsiveImage,
  AspectRatioWrapper,
  H4,
  NamedLink,
  IconScaner,
  IconLocationPin
} from '../../components';
import { languagesHelper as language } from '../../helpers/languages';

import css from './ListingCard.module.css';
import BookingTimeFormForCard from './BookingTimeFormForCard/BookingTimeFormForCard';

const TODAY = new Date();

const MAX_LANDSCAPE_ASPECT_RATIO = 2; // 2:1
const MAX_PORTRAIT_ASPECT_RATIO = 4 / 3;
const MARKETPLACE_CURRENCY = process.env.REACT_APP_SHARETRIBE_MARKETPLACE_CURRENCY;

const getFirstImageAspectRatio = (firstImage, scaledVariant) => {
  if (!firstImage) {
    return { aspectWidth: 1, aspectHeight: 1 };
  }

  const v = firstImage?.attributes?.variants?.[scaledVariant];
  const w = v?.width;
  const h = v?.height;
  const hasDimensions = !!w && !!h;
  const aspectRatio = w / h;

  return hasDimensions && aspectRatio >= MAX_LANDSCAPE_ASPECT_RATIO
    ? { aspectWidth: 2, aspectHeight: 1 }
    : hasDimensions && aspectRatio <= MAX_PORTRAIT_ASPECT_RATIO
      ? { aspectWidth: 4, aspectHeight: 3 }
      : hasDimensions
        ? { aspectWidth: w, aspectHeight: h }
        : { aspectWidth: 1, aspectHeight: 1 };
};

const typeLabel = (type, intl) => {
  return language.labelsTranslator(defaultConfig?.typeOfScanerOptions?.filter(item => item.key === type)?.[0]?.key, intl)
}

const renderLeftNav = (onClick, disabled) => {
  return (
    <button className={css.navLeft} disabled={disabled} onClick={onClick}>
      <div className={css.navArrowWrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="27"
          fill="none"
          viewBox="0 0 32 27"
        >
          <path fill="#fff" d="M32 4a4 4 0 00-4-4H0v27h28a4 4 0 004-4V4z"></path>
          <path
            fill="#323232"
            d="M13.219 13.333h8.115v1.334h-8.115l3.576 3.576-.943.942L10.667 14l5.185-5.185.943.942-3.576 3.576z"
          ></path>
        </svg>
      </div>
    </button>
  );
};

const renderRightNav = (onClick, disabled) => {
  return (
    <button className={css.navRight} disabled={disabled} onClick={onClick}>
      <div className={css.navArrowWrapper}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="27"
          fill="none"
          viewBox="0 0 32 27"
        >
          <path fill="#fff" d="M0 4a4 4 0 014-4h28v27H4a4 4 0 01-4-4V4z"></path>
          <path
            fill="#323232"
            d="M18.781 14.667h-8.115v-1.334h8.115l-3.576-3.576.943-.942L21.333 14l-5.185 5.185-.943-.942 3.576-3.576z"
          ></path>
        </svg>
      </div>
    </button>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    setActiveListing,
    onManageDisableScrolling,
    onFetchTimeSlots,
    marketplaceCurrency,
    currentUser,
    marketplaceName,
    dayCountAvailableForBooking,
    onFetchBookingInfo,
    monthlyTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    onInitializeCardPaymentData,
    callSetInitialValues,
    history,
    routeConfiguration,
    getListing,
    currentBrowserGeolocation,
    adminProfile,
    getAdminProfileInProgress,
    getAdminProfileError,
    onOpenLoginPopup,
    conversionRates,
    currentCurrency,
  } = props;

  useEffect(() => {
    onFetchBookingInfo(listing)
  }, []);

  // const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const timeZone = "CET";

  const authorAvailable = listing && listing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && listing.author.id.uuid === currentUser.id.uuid;

  const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

  const unitType = listing?.attributes?.publicData?.unitType;
  const lineItemUnitType = `line-item/${unitType}`;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);

  const variantPrefix = config.layout.listingImage.variantPrefix;
  const images = listing.images;
  const imageVariants = ['scaled-small', 'scaled-medium', 'scaled-large', 'scaled-xlarge'];
  const thumbnailVariants = [variantPrefix, `${variantPrefix}-2x`, `${variantPrefix}-4x`];
  const thumbVariants = thumbnailVariants || imageVariants;

  const { title, publicData, price, geolocation } = currentListing?.attributes;
  const { typeOfScaner = null, location = null, currency = MARKETPLACE_CURRENCY } = publicData;
  const typeOfScanerValues = !!typeOfScaner?.length ? typeOfScaner?.map(item => typeLabel(item, intl)) : null

  const listingId = currentListing?.id?.uuid;
  const listingSlug = createSlug(title);

  const { aspectWidth, aspectHeight } = getFirstImageAspectRatio(images?.[0], imageVariants[0]);
  const imageSizesMaybe = { sizes: `(max-width: 1024px) 100vw, (max-width: 1200px) calc(100vw - 192px), 708px` };
  const items = images.map((img, i) => {
    return {
      // We will only use the image resource, but react-image-gallery
      // requires the `original` key from each item.
      original: '',
      alt: intl.formatMessage(
        { id: 'ListingImageGallery.imageAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbAlt: intl.formatMessage(
        { id: 'ListingImageGallery.imageThumbnailAltText' },
        { index: i + 1, count: images.length }
      ),
      thumbnail: img.attributes?.variants?.[thumbVariants[0]],
      image: img,
    };
  });

  const renderItem = item => {
    return (
      <NamedLink className={css.rootLinkImg} name="ListingPage" params={{ id: listingId, slug: listingSlug }}>
        <AspectRatioWrapper
          width={aspectWidth || 1}
          height={aspectHeight || 1}
          className={css.itemWrapper}
        >
          <div className={css.itemCentering}>
            <ResponsiveImage
              rootClassName={css.item}
              image={item.image}
              alt={item.alt}
              variants={imageVariants}
              {...imageSizesMaybe}
            />
          </div>
        </AspectRatioWrapper>
      </NamedLink>
    );
  };

  if (items.length === 0) {
    return <ResponsiveImage className={css.noImage} image={null} variants={[]} alt="" />;
  }

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const monthlyTimeSlotsValues = monthlyTimeSlots.filter(item => item.listingId.uuid === listingId)?.[0]?.monthSlot;

  const distanceBetweenListings = getDistanceFromLatLonInKm(
    [currentBrowserGeolocation?.lat, currentBrowserGeolocation?.lng],
    [geolocation.lat, geolocation.lng]
  );

  const mainInfo = (
    <div className={css.rootLinkInfo}>
      <H4 as="h2" className={css.orderPanelTitle}>
        {title}
      </H4>
      <div className={css.infoItems}>
        {!!typeOfScanerValues?.length && (
          <div className={css.infoItem}>
            <IconScaner />
            {typeOfScanerValues?.join(", ")}
          </div>
        )}
        {!!location?.address && (
          <div className={css.infoItem}>
            <span>
              <IconLocationPin />
              {location.address}
            </span>
            {!!currentBrowserGeolocation?.lat && !!currentBrowserGeolocation?.lng
              && (
                <span className={css.grayText}>
                  <FormattedMessage id="ListingCard.distance" values={{ distance: distanceBetweenListings }} />
                </span>
              )
            }
          </div>
        )}
        <div className={css.rootLinkButton}>
          <FormattedMessage id="ListingCard.viewProfile" />
        </div>
      </div>
    </div>
  )

  const isPatient = isPatientRole(currentUser);
  const isDentist = isDentistRole(currentUser);

  const { scanTypes } = adminProfile?.attributes?.profile?.publicData ?? {};


  return (
    <div
      className={css.root}
      {...setActivePropsMaybe}
    >
      <ReactImageGallery
        additionalClass={classNames(css.productGallery, { [css.productGallerySingle]: items?.length === 1 })}
        items={items}
        renderItem={renderItem}
        renderLeftNav={renderLeftNav}
        renderRightNav={renderRightNav}
        showPlayButton={false}
        disableThumbnailScroll={true}
        showThumbnails={false}
        showFullscreenButton={false}
        showBullets={true}
      />

      <NamedLink className={css.rootLink} name="ListingPage" params={{ id: listingId, slug: listingSlug }}>
        {mainInfo}
        <div className={css.rootLinkButtonLargeDesktop}>
          <FormattedMessage id="ListingCard.viewProfile" />
        </div>
      </NamedLink>

      {!isDentist && (

        <div
          className={css.timeSlotsHolder}
          onClick={() => onOpenLoginPopup && !currentUser && onOpenLoginPopup()}>
          <div
            className={classNames(
              { [css.timeSlotsHolderBlocked]: !currentUser }
            )}
          >
            <BookingTimeFormForCard
              className={css.bookingForm}
              formId="BookingTimeFormForCard"
              lineItemUnitType={lineItemUnitType}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing?.id}
              listing={listing}
              listingSlug={listingSlug}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlotsValues}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone="CET"
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              onManageDisableScrolling={onManageDisableScrolling}

              listingMainInfo={mainInfo}
              onInitializeCardPaymentData={onInitializeCardPaymentData}
              callSetInitialValues={callSetInitialValues}
              history={history}
              routeConfiguration={routeConfiguration}
              currentUser={currentUser}
              getListing={getListing}
              scanTypes={scanTypes}
              typesOfScans={typeOfScaner}
              getAdminProfileInProgress={getAdminProfileInProgress}
              getAdminProfileError={getAdminProfileError}
              conversionRates={conversionRates}
              currentCurrency={currentCurrency}
              listingCurrency={currency}
            />
          </div>
        </div>

      )}
      {/* <div className={css.rootContent}>
      </div> */}
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
