import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconSearchRadius.module.css';

const IconSearchRadius = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#323232"
        d="M18.172 13l.487.57zm-1.659.43a.75.75 0 10.974 1.14zm.974-4a.75.75 0 10-.974 1.14zm.685 1.57l.487-.57zm-.487 1.43l-1.172 1 .974 1.14 1.172-1zm-1.172-1.86l1.172 1 .974-1.14-1.172-1zm2.145 3c.26-.22.514-.436.694-.637.19-.212.398-.517.398-.933h-1.5c0-.123.07-.164-.016-.067-.097.108-.256.246-.55.496zm-.973-2c.293.25.453.39.549.497.086.097.016.056.016-.067h1.5c0-.415-.208-.72-.398-.933-.18-.201-.434-.416-.694-.638z"
      ></path>
      <g stroke="#323232" strokeWidth="1.5">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M13.5 12H19"
        ></path>
        <path d="M12 13.5a1.5 1.5 0 110-3 1.5 1.5 0 010 3z"></path>
        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10z"></path>
      </g>
    </svg>
  );
};

IconSearchRadius.defaultProps = {
  rootClassName: null,
  className: null,
  pencilClassName: null,
};

const { string } = PropTypes;

IconSearchRadius.propTypes = {
  rootClassName: string,
  className: string,
  pencilClassName: string,
};

export default IconSearchRadius;
