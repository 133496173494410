import React, { useState, useEffect } from 'react';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { createResourceLocatorString } from '../../util/routes';
import AdvancedSearchForm from './AdvancedSearchForm';
import classNames from 'classnames';
import { parse, stringify } from '../../util/urlHelpers';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { types as sdkTypes } from '../../util/sdkLoader';
import defaultConfig from '../../config/configDefault';

import css from './AdvancedSearch.module.css';
const { LatLng, LatLngBounds } = sdkTypes;

const earthRadiusKm = 6371;

const kmToLatitudeDegrees = (km) => km / 111;
const kmToLongitudeDegrees = (km, latitude) => km / (111 * Math.cos(latitude * (Math.PI / 180)));

const expandBoundingBox = (coords, radiusKm) => {
  const { ne, sw } = coords;

  const latitudeExpansion = kmToLatitudeDegrees(radiusKm);
  const longitudeExpansionNE = kmToLongitudeDegrees(radiusKm, ne.lat);
  const longitudeExpansionSW = kmToLongitudeDegrees(radiusKm, sw.lat);

  const newNE = {
    lat: ne.lat + latitudeExpansion,
    lng: ne.lng + longitudeExpansionNE
  };

  const newSW = {
    lat: sw.lat - latitudeExpansion,
    lng: sw.lng - longitudeExpansionSW
  };

  return new LatLngBounds(new LatLng(newNE.lat, newNE.lng), new LatLng(newSW.lat, newSW.lng));
}

const AdvancedSearch = props => {
  const { intl, topbarLayout, currentPage, location } = props;

  const selectedFilters = parse(location?.search) || {};
  const selectedRadius = !!selectedFilters?.pub_locationRadius ? defaultConfig.radiusOptions.find(item => +item.key === selectedFilters?.pub_locationRadius) : null;
  const selectedTypeOfScaner = !!selectedFilters?.pub_typeOfScaner ? defaultConfig.typeOfScanerOptions.find(item => item.key === selectedFilters?.pub_typeOfScaner) : null;

  const routeConfiguration = useRouteConfiguration();

  const [selectFilterOpen, setSelectFilterOpen] = useState(false);
  const [radiusFilterOpen, setRadiusFilterOpen] = useState(false);

  const [selectValue, setSelectValue] = useState(null);
  const [radiusValue, setRadiusValue] = useState(!!selectedRadius ? selectedRadius : { key: '20', label: '20km' });
  const [locationValue, setLocationValue] = useState(null);

  useEffect(() => {
    if(!selectedFilters?.pub_locationRadius) {
      setRadiusValue({ key: '20', label: '20km' })
    }
    if(!selectedFilters?.pub_typeOfScaner) {
      setSelectValue(null)
    }
    if(!selectedFilters?.address) {
      setLocationValue(null)
    }
  }, [location])

  const updateSelectValue = type => {
    setSelectValue(type)
    setSelectFilterOpen(false)
  }

  const applySelectFilter = status => {
    setSelectFilterOpen(status)
  }

  const updateRadiusValue = type => {
    setRadiusValue(type)
    setRadiusFilterOpen(false)
  }
  const applyRadiusFilter = status => {
    setRadiusFilterOpen(status)
  }

  const onSubmit = () => {
    return;
  };

  const getValues = values => {
    const { history } = props;

    const locationRadius = +radiusValue?.key || 0;

    const searchParams = {
      address: !!values?.location?.selectedPlace?.address
        ? values?.location?.selectedPlace?.address
        : null,
      bounds: !!values?.location?.selectedPlace?.bounds
        ? expandBoundingBox(values?.location?.selectedPlace?.bounds, locationRadius)
        : expandBoundingBox(new LatLngBounds(new LatLng(48.34182478, 9.27554237), new LatLng(44.97276078, 6.02908241)), locationRadius),
      origin: null,

      // origin: !!values?.location?.selectedPlace?.origin
      //   ? values?.location?.selectedPlace?.origin
      //   : null,

      pub_typeOfScaner: selectValue?.key || selectedTypeOfScaner?.key || null,
      pub_locationRadius: radiusValue?.key || null,
    };

    !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
  };

  const onClearLocation = () => {
    const { history } = props;

    const searchParams = {
      address: null,
      bounds: null,
      origin: null,
      pub_typeOfScaner: selectValue?.key || null,
      pub_locationRadius: radiusValue?.key || null
    };
    if (currentPage === "SearchPage") {
      !!history && history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    }
  };

  const { address, origin, bounds } = parse(location?.search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const initialSearchFormValues = {
    location: locationValue || {
      search: address,
      selectedPlace: { address, origin, bounds },
    }
  };

  const handleLocationChange = (formValues) => {
    if (!!formValues?.values?.location?.selectedPlace) {
      setLocationValue(formValues?.values?.location)
    }
  }

  return (
    <div className={classNames(css.root, { [css.rootTopbar]: currentPage !== "LandingPage" })}>
      <AdvancedSearchForm
        topbarLayout={topbarLayout}
        onSubmit={onSubmit}
        getValues={getValues}
        intl={intl}

        updateSelectValue={updateSelectValue}
        applySelectFilter={applySelectFilter}
        selectFilterOpen={selectFilterOpen}
        selectValue={selectValue}

        updateRadiusValue={updateRadiusValue}
        applyRadiusFilter={applyRadiusFilter}
        radiusFilterOpen={radiusFilterOpen}
        radiusValue={radiusValue}

        initialValues={initialSearchFormValues}
        onClearLocation={onClearLocation}
        handleLocationChange={handleLocationChange}

        currentPage={currentPage}
        selectedFilters={selectedFilters}
      />
    </div>
  );
};

AdvancedSearch.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(AdvancedSearch);
