/*
 * Renders a group of checkboxes that can be used to select
 * multiple values from a set of options.
 *
 * The corresponding component when rendering the selected
 * values is PropertyGroup.
 *
 */

import React, { useState } from 'react';
import { arrayOf, bool, node, shape, string } from 'prop-types';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { Form as FinalForm, Field } from 'react-final-form';
import {
  FieldCheckbox,
  ValidationError,
  FieldTextInput,
  FieldCheckboxGroup,
  FieldRadioButton,
  FieldSelect,
  IconClose,
  IconAdd,
  IconArrowHead,
} from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import { useConfiguration } from '../../context/configurationContext';
import appSettings from '../../config/settings';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import defaultConfig from '../../config/configDefault';
import { languagesHelper as language } from '../../helpers/languages';

import css from './DentalServicesField.module.css';

const { Money } = sdkTypes;

const getPriceValidators = (listingMinimumPriceSubUnits, marketplaceCurrency, intl) => {
  const priceRequiredMsgId = { id: 'EditListingPricingForm.priceRequired' };
  const priceRequiredMsg = intl.formatMessage(priceRequiredMsgId);
  const priceRequired = validators.required(priceRequiredMsg);

  const minPriceRaw = new Money(listingMinimumPriceSubUnits, marketplaceCurrency);
  const minPrice = formatMoney(intl, minPriceRaw);
  const priceTooLowMsgId = { id: 'EditListingPricingForm.priceTooLow' };
  const priceTooLowMsg = intl.formatMessage(priceTooLowMsgId, { minPrice });
  const minPriceRequired = validators.moneySubUnitAmountAtLeast(
    priceTooLowMsg,
    listingMinimumPriceSubUnits
  );

  return listingMinimumPriceSubUnits
    ? validators.composeValidators(priceRequired, minPriceRequired)
    : priceRequired;
};

const DentalServicesRenderer = props => {
  const {
    className,
    rootClassName,
    label,
    optionLabelClassName,
    twoColumns,
    id,
    fields,
    options,
    meta,
    withPrice,
    listingMinimumPriceSubUnits = 0,
    intl,
    values,
    form,
    push,
    listing
  } = props;

  const [currentIndex, setCurrentIndex] = useState(0)

  const classes = classNames(rootClassName || css.root, className);
  const listClasses = twoColumns ? classNames(css.list, css.twoColumns) : css.list;
  const config = useConfiguration();

  const priceValidators = withPrice && getPriceValidators(
    listingMinimumPriceSubUnits,
    config.currency,
    intl
  );

  const onChangeScanType = (e, type, index) => {

    e.stopPropagation();

    form.change(`price${type}`, null);
    form.change(`manufacturer${type}`, null);
    form.change(`model${type}`, null);
    form.change(`serviceDuration${type}`, null);

    if (type === 'dentalCheckupExam' || type === 'cleaningAndTartarRemoval') {
      form.change(`basicServices${type}`, null);
      form.change(`optionalServices${type}`, null);
      form.change(`technician${type}`, null);
      form.change(`additionalDetails${type}`, null);
    }

    setCurrentIndex(index)
  };

  const onHandleAddService = (type) => {
    push(type, '');
  }

  const onHandleChangeIndex = (index) => {
    if (index === currentIndex) setCurrentIndex(-1)
    else setCurrentIndex(index)
  }

  const listingCurrency = listing?.attributes?.publicData?.currency;

  return (
    <fieldset className={classes}>
      {label ? <legend>{label}</legend> : null}
      <ul className={listClasses}>
        {options.map((option, index) => {
          const fieldId = `${id}.${option.key}`;
          const textClassName = optionLabelClassName;
          const textClassNameMaybe = textClassName ? { textClassName } : {};
          const isSelected = withPrice && values?.typeOfScaner?.includes(option.key);

          return (
            <li
              key={fieldId}
              className={classNames(
                css.item,
                { [css.itemWithPrice]: withPrice },
                { [css.itemActive]: currentIndex === index }
              )}
            >
              <span onClick={(e) => withPrice ? onChangeScanType(e, option.key, index) : {}}>
                <FieldCheckbox
                  id={fieldId}
                  name={fields.name}
                  label={option.label}
                  value={option.key}
                  {...textClassNameMaybe}
                />
              </span>

              {withPrice && isSelected && (

                <div className={css.itemListHolder}>
                  <h4
                    className={css.itemListTitle}
                    onClick={() => onHandleChangeIndex(index)}
                  >
                    <span>
                      {`Service - ${option.label}`}
                    </span>

                    <IconArrowHead direction={currentIndex === index ? 'up' : 'down'} />
                  </h4>

                  <div className={css.itemListContent}>

                    <div className={css.additionalFields}>

                      <div className={css.priceHolder}>
                        <FieldTextInput
                          type="number"
                          id={`price${fieldId}`}
                          name={`price${option.key}`}
                          className={css.numberInput}
                          label={intl.formatMessage({
                            id: 'EditListingDetailsForm.scannerPrice',
                          })}
                          placeholder={intl.formatMessage({ id: 'EditListingPricingForm.priceInputPlaceholder' })}
                          currencyConfig={appSettings.getCurrencyFormatting(config.currency)}
                          validate={priceValidators}
                          min={0}
                          step={0.01}
                          isRequired
                        />
                        <div className={css.currency}>
                          {listingCurrency || config.currency}
                        </div>
                      </div>

                      {option.key === "dentalCheckupExam" || option.key === "cleaningAndTartarRemoval" ? (
                        <>
                          <div className={css.textInput}>
                            <label htmlFor="" className={css.required}>
                              {intl.formatMessage({
                                id: 'EditListingDetailsForm.includedBasicServices',
                              })}
                            </label>
                            <FieldCheckboxGroup
                              className={css.features}
                              id={`basicServices${fieldId}`}
                              name={`basicServices${option.key}`}
                              options={
                                option.key === "cleaningAndTartarRemoval" ?
                                  defaultConfig.cleaningAndTartarRemovalBasicServices :
                                  defaultConfig.dentalCheckupBasicServices
                              }
                              intl={intl}
                              validate={validators.requiredFieldArrayCheckbox(
                                intl.formatMessage({ id: 'EditListingDetailsForm.includedBasicServicesRequired' })
                              )}
                              withPrice
                              values={values}
                              form={form}
                              withTranslations
                              isRequired
                            />
                          </div>

                          <div className={css.textInput}>
                            <div className={css.optionalServiceTitle}>

                              <label htmlFor="">
                                {intl.formatMessage({
                                  id: 'EditListingDetailsForm.includedOptionalServices',
                                })}
                              </label>
                              {(!values[`optionalServices${option.key}`]?.length || values[`optionalServices${option.key}`]?.length < 3) && (
                                <div onClick={() => onHandleAddService(`optionalServices${option.key}`)}>
                                  <IconAdd />
                                </div>
                              )}
                            </div>

                            <FieldArray name={`optionalServices${option.key}`}>
                              {({ fields }) => {
                                return fields.map((name, index) => {

                                  const placeholders = [
                                    'Optional: List any additional service included in price.',
                                    'Optional: additional service 2',
                                    'Optional: additional service 3'
                                  ];

                                  const showError = values?.[`optionalServices${option.key}`]?.[index]?.length > 50;

                                  return (
                                    <>
                                      <div
                                        key={`${name}-${index}`}
                                        className={classNames(
                                          css.optionalService,
                                          { [css.optionalServiceInvalid]: showError }
                                        )}
                                      >
                                        <Field
                                          name={name}
                                          component="input"
                                          placeholder={placeholders[index]}
                                          validate={validators.composeValidators(
                                            validators.maxLength(intl.formatMessage(
                                              { id: 'EditListingDetailsForm.maxLength' },
                                              { maxLength: 50 }
                                            ), 50))}
                                        />
                                        <div
                                          className={css.closeIconWrapper}
                                          onClick={() => {
                                            fields.remove(index);
                                          }}
                                        >
                                          <IconClose />
                                        </div>
                                      </div>
                                      {showError && (
                                        <div className={css.error}>
                                          <FormattedMessage
                                            id="EditListingDetailsForm.maxLength"
                                            values={{ maxLength: 50 }}
                                          />
                                        </div>
                                      )}
                                    </>
                                  );
                                });
                              }}
                            </FieldArray>
                          </div>

                          <div className={css.textInput}>
                            <label className={css.required}>
                              {intl.formatMessage({
                                id: 'EditListingDetailsForm.technician',
                              })}
                            </label>
                            <FieldRadioButton
                              label="Performed by a licensed dentist"
                              id={`technician${fieldId}-byRegisteredDentist`}
                              name={`technician${option.key}`}
                              value="Performed by a licensed dentist"
                            />
                            {option.key === "cleaningAndTartarRemoval" && (
                              <FieldRadioButton
                                label="Performed by a dental hygienist"
                                id={`technician${fieldId}-byDentalHygienist`}
                                name={`technician${option.key}`}
                                value="Performed by a dental hygienist"
                              />
                            )}
                          </div>

                          <div className={css.textInput}>
                            <label htmlFor="">
                              {intl.formatMessage({
                                id: 'EditListingDetailsForm.additionalDetails',
                              })}
                            </label>
                            <FieldTextInput
                              className={css.textInput}
                              type="textarea"
                              id={`additionalDetails${fieldId}`}
                              name={`additionalDetails${option.key}`}
                              placeholder={'Optional: add additional service description if desired'}
                              validate={validators.composeValidators(
                                validators.maxLength(intl.formatMessage(
                                  { id: 'EditListingDetailsForm.maxLength' },
                                  { maxLength: 150 }
                                ), 150))}
                            />
                          </div>
                        </>
                      ) : (
                        <div>

                          <div className={css.textInput}>
                            <label htmlFor="">
                              {intl.formatMessage({
                                id: 'EditListingDetailsForm.includedBasicServices',
                              })}
                            </label>
                            <FieldCheckboxGroup
                              className={css.features}
                              id={`basicServices${fieldId}`}
                              name={`basicServices${option.key}`}
                              options={
                                defaultConfig?.scanBasicServices?.find(s => s.scan === option.key)?.options
                              }
                              intl={intl}
                              // validate={validators.requiredFieldArrayCheckbox(
                              //   intl.formatMessage({ id: 'EditListingDetailsForm.scanTypeRequired' })
                              // )}
                              withPrice
                              values={values}
                              form={form}
                              withTranslations
                            />
                          </div>

                          <div className={css.textInput}>
                            <label className={css.required}>
                              {intl.formatMessage({
                                id: 'EditListingDetailsForm.technician',
                              })}
                            </label>
                            <FieldRadioButton
                              label="Performed by a licensed dentist"
                              id={`technician${fieldId}-byRegisteredDentist`}
                              name={`technician${option.key}`}
                              value="Performed by a licensed dentist"
                            />
                            <FieldRadioButton
                              label="Performed by a dental hygienist"
                              id={`technician${fieldId}-byDentalHygienist`}
                              name={`technician${option.key}`}
                              value="Performed by a dental hygienist"
                            />
                          </div>

                          <FieldTextInput
                            className={css.textInput}
                            type="text"
                            id={`manufacturer${fieldId}`}
                            name={`manufacturer${option.key}`}
                            label={intl.formatMessage({
                              id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayManufacturerLabel' : 'EditListingDetailsForm.scannerManufacturerLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayManufacturerPlaceholder' : 'EditListingDetailsForm.scannerManufacturerPlaceholder',
                            })}
                            validate={validators.composeValidators(
                              validators.required(
                                intl.formatMessage({
                                  id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayManufacturerRequired' : 'EditListingDetailsForm.scannerManufacturerRequired'
                                })
                              ),
                              validators.maxLength(intl.formatMessage(
                                { id: 'EditListingDetailsForm.maxLength' },
                                { maxLength: 60 }
                              ), 60))}
                          />
                          <FieldTextInput
                            className={css.textInput}
                            type="text"
                            id={`model${fieldId}`}
                            name={`model${option.key}`}
                            label={intl.formatMessage({
                              id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayModelLabel' : 'EditListingDetailsForm.scannerModelLabel',
                            })}
                            placeholder={intl.formatMessage({
                              id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayModelPlaceholder' : 'EditListingDetailsForm.scannerModelPlaceholder',
                            })}
                            validate={validators.composeValidators(
                              validators.required(
                                intl.formatMessage({
                                  id: (option.key === 'standardXRay' || option.key === 'panoramicXRay') ? 'EditListingDetailsForm.xrayModelRequired' : 'EditListingDetailsForm.scannerModelRequired'
                                })
                              ),
                              validators.maxLength(intl.formatMessage(
                                { id: 'EditListingDetailsForm.maxLength' },
                                { maxLength: 60 }
                              ), 60))}
                          />
                        </div>
                      )}

                      <FieldSelect
                        id={`serviceDuration${fieldId}`}
                        name={`serviceDuration${option.key}`}
                        // className={classNames(css.selectField, { [css.selectFieldNotSelected]: !values?.userRole })}
                        className={css.selectField}
                        label={intl.formatMessage({ id: 'EditListingDetailsForm.serviceDurationLabel' })}
                        validate={validators.required(
                          intl.formatMessage({
                            id: 'EditListingDetailsForm.serviceDurationRequired',
                          })
                        )}
                        isRequired
                      >
                        <option disabled value="">
                          {intl.formatMessage({ id: 'EditListingDetailsForm.serviceDurationPlaceholder' })}
                        </option>
                        {defaultConfig?.serviceDurationOptions.map(duration => (
                          <option key={duration.key} value={duration.key}>
                            {language.labelsTranslator(duration.key, intl)}
                          </option>
                        ))}
                      </FieldSelect>
                    </div>
                  </div>
                </div>
              )}
            </li>
          );
        })}
      </ul>
      <ValidationError fieldMeta={{ ...meta }} />
    </fieldset >
  );
};

DentalServicesRenderer.defaultProps = {
  rootClassName: null,
  className: null,
  label: null,
  twoColumns: false,
};

DentalServicesRenderer.propTypes = {
  rootClassName: string,
  className: string,
  id: string.isRequired,
  label: node,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: node.isRequired,
    })
  ).isRequired,
  twoColumns: bool,
};

const DentalServicesField = props => <FieldArray component={DentalServicesRenderer} {...props} />;

// Name and component are required fields for FieldArray.
// Component-prop we define in this file, name needs to be passed in
DentalServicesField.propTypes = {
  name: string.isRequired,
};

export default DentalServicesField;
