import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconLocationPin.module.css';

const IconLocationPin = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);
  return (
    <svg
      className={classes}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#323232"
        d="M18.75 9.75a6.75 6.75 0 00-13.5 0c0 2.769 2.215 6.378 6.75 10.701 4.535-4.323 6.75-7.932 6.75-10.701zM12 22.5c-5.5-5-8.25-9.25-8.25-12.75a8.25 8.25 0 1116.5 0c0 3.5-2.75 7.75-8.25 12.75z"
      ></path>
      <path
        fill="#323232"
        d="M12 12a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5zm0 1.5A3.75 3.75 0 1112 6a3.75 3.75 0 010 7.5z"
      ></path>
    </svg>
  );
};

IconLocationPin.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

IconLocationPin.propTypes = {
  rootClassName: string,
  className: string,
};

export default IconLocationPin;
